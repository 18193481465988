import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Formik } from "formik";
import {
  Box,
  Button,
  Card,
  Container,
  CardContent,
  Checkbox,
  FormHelperText,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TextField,
  InputAdornment,
  SvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Collapse,
  makeStyles
} from "@material-ui/core";
import { Search as SearchIcon } from "react-feather";
import { DataStore, SortDirection } from "aws-amplify";
import { NewsLetter } from "../../models";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import * as API from "../../utils/api";
import ReactFileReader from 'react-file-reader';

// import MaterialJsonSchemaForm from 'react-jsonschema-form-material-ui';


const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const Results = ({ className, dados, ...rest }) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAdicionar, setOpenAdicionar] = useState(false);
  const [userEdit, setUserEdit] = useState();
  const [ofertaUsers, setOfertaUsers] = useState([]);
  const [search, setSearch] = useState();
  const [customers, setCustomers] = useState(dados);


  // const [customers, setCustomers] = useState(data);
  const [values, setValues] = React.useState({
    title: "",
    body: "",
    data: {
      data: ""
    }
  });

  useEffect(() => {
    if ((!search)||(search == '')||(typeof(search)==undefined))
    {
      setCustomers(dados);
    } else {
      const query = dados.filter(el => (((el?.nome?.toLowerCase()).includes(search.toLowerCase())) || 
                                        ((el?.cidade?.toLowerCase()).includes(search.toLowerCase())) ||
                                        ((el?.empresa?.toLowerCase()).includes(search.toLowerCase()))
                                        ));
      setCustomers(query);
    }
    return () => {
    }
  }, [search, dados])

  const handleClose = () => {
    setOpenEdit(false);
    setOpenAdicionar(false);
  };

  const handleSelectAll = event => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = customers.map(customer => customer.id);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(1)
      );
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = event => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  function openUrl(url) {
    const win = window.open(url, "_blank");
    if (win != null) {
      win.focus();
    }
  }

  function formatPhoneNumber(phoneNumberString) {
    var numberPattern = /\d+/g;
    var match = phoneNumberString.match(numberPattern);
    return `+55${match}`;
  }


 const showFile = async (e) => {
  e.preventDefault()
  const reader = new FileReader()
  reader.onload = async (e) => { 
    const text = (e.target.result)
    //console.log(text)
    text.split("\n").map(async (i,key) => {
      //console.log(`<div key=${key}>${i}</div>;`);
      const info = i.split(";");
      const nome = info[1]||'';
      const cnpj = info[2]||'';
      const ie = info[3]||'';
      const cidade = info[4]||'';
      const email = info[5]||'';
      const telefone = info[6]||'';
      console.log(nome);
      const resultado = await DataStore.save(
        new NewsLetter({
          nome: nome,
          telefone: telefone,
          email: email,
          cidade: cidade,
          estado: cidade,
          empresa: cnpj,
          criadoData: new Date().toISOString()
        })
      );
    })
    //alert(text)
  };
  reader.readAsText(e.target.files[0])
}


  async function ApagarLinha(id) {
    const modelToDelete = await DataStore.query(NewsLetter, id);

    if (window.confirm(`Deseja apagar o inscrito ${modelToDelete.nome} selecionada?`)) {

      if (
        window.confirm(
          `Comfirma a exclusão de ${modelToDelete.nome}?\nEssa operação não pode ser desfeita.`
        )
      ) {
        const sucesso = DataStore.delete(modelToDelete);
        if (sucesso) {
          alert("Usuário excluído com sucesso");
          setSelectedCustomerIds([]);
        }
      }
    }
  }

  function testObject(valor) {
    return ((valor != null) && (typeof (valor) === "object"));
  }

  async function getUser(id) {
    const user =  await customers.find(
      element => element.id == id
    );
    return user;
  }

  async function enviarEmailMulti(template) {

    if (window.confirm(`Deseja enviar o email de ${template} para todos?`)) {


      for (var obj in selectedCustomerIds) {
        const sendID = selectedCustomerIds[obj];
        const usuario = await getUser(sendID);
        console.log(usuario.email);
  
        const mensagem = {
          ...usuario
        }
  
        await API.enviarEmail(usuario.email, template, mensagem);
      }


    }

  }


  function TableLinha(props) {
    const { oferta, index } = props;
    const [user, setUser] = useState({ nome: 'Usuario Agrify', fotoUrl: null });
    const [negocios, setNegocios] = useState([]);
    // const [fotos, setFotos] = useState([]);
    const [open, setOpen] = React.useState(false);

    useEffect(async () => {
      if (ofertaUsers[oferta.id]) {
        setUser(ofertaUsers[oferta.id].user);
        setNegocios(ofertaUsers[oferta.id].business);
      }
      return () => {
      }
    }, [ofertaUsers[oferta.id]])

    return (<React.Fragment><TableRow
      hover
      key={oferta.id}
      selected={selectedCustomerIds.indexOf(oferta.id) !== -1}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={selectedCustomerIds.indexOf(oferta.id) !== -1}
          onChange={(event) => handleSelectOne(event, oferta.id)}
          value="true"
        />
      </TableCell>

      <TableCell>
        <IconButton aria-label="expand row" disabled={((!negocios) || (negocios.length < 1))} size="small" onClick={() => setOpen(!open)}>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell>

      <TableCell>
        {oferta.nome}
      </TableCell>

      <TableCell>
        {oferta.email}
      </TableCell>

      <TableCell>
        {oferta.telefone}
      </TableCell>

      <TableCell>
        {oferta.empresa}
      </TableCell>

      <TableCell>
        {oferta.cidade}
      </TableCell>

      <TableCell>
        {oferta.estado}
      </TableCell>

      <TableCell>
          <Button
            color="primary"
            variant="contained"
            className={classes.exportButton}
            //disabled={selectedCustomerIds.length != 1}
            onClick={() => {
              const found = oferta;

              setUserEdit(found);
              setOpenEdit(true);

            }}
          >
            Editar
          </Button>
      </TableCell>


    </TableRow>

    </React.Fragment>);
  }




  return (
    <div>
      <div>
        <Box>
          <Card>
            <CardContent>
              <div
                style={{
                  flexDirection: "row",
                  alignContent: "center",
                  justifyContent: "center"
                }}
              >
                <Box>
                  <TextField
                    fullWidth
                    // maxWidth={500}
                    style={{ flex: 1, alignSelf: 'stretch' }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    placeholder="Procurar Inscrito"
                    variant="outlined"
                    value={search}
                    onChange={(event) => {
                      setSearch(event.target.value);
                    }}
                  />
                </Box>
                <Box
                  mt={3}
                >
                  <Button
                    className={classes.importButton}
                    disabled={selectedCustomerIds.length < 1}
                    onClick={() => {
                      enviarEmailMulti('template_convite_agrify');
                    }}
                  >
                    Enviar E-mail
                  </Button> 

                  <input
                    //accept="image/*"
                    className={classes.input}
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    onChange={(e) => showFile(e)}
                    multiple
                    type="file"
                  />
                  <label htmlFor="raised-button-file">
                    <Button 
                      color="primary"
                      variant="contained"
                      className={classes.exportButton} component="span">
                      Enviar CSV
                    </Button>
                  </label> 

                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.exportButton}
                    //disabled={selectedCustomerIds.length != 1}
                    onClick={() => {
                      const found = new NewsLetter(
                        {
                          nome: '',
                          email: '',
                          telefone: '',
                          criadoData: new Date().toISOString()
                      
                        }
                      )
                      setUserEdit(found);
                      setOpenAdicionar(true);
                      const file = null; //'template_rascunho.html'

                    }}
                  >
                    Novo
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.exportButton}
                    disabled={selectedCustomerIds.length != 1}
                    onClick={() => {
                      const found = customers.find(
                        element => element.id == selectedCustomerIds[0]
                      );
                      setUserEdit(found);
                      setOpenEdit(true);

                    }}
                  >
                    Editar
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={selectedCustomerIds.length != 1}
                    onClick={() => {
                      ApagarLinha(selectedCustomerIds[0]);
                    }}
                  >
                    Apagar
                  </Button>
                </Box>
              </div>
            </CardContent>
          </Card>
        </Box>
      </div>
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table>

              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedCustomerIds.length === customers.length}
                      color="primary"
                      indeterminate={
                        selectedCustomerIds.length > 0
                        && selectedCustomerIds.length < customers.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>
                  </TableCell>
                  <TableCell>
                    Nome
                  </TableCell>
                  <TableCell>
                    E-mail
              </TableCell>
              <TableCell>
                    Telefone
              </TableCell>
              <TableCell>
                    Empresa
              </TableCell>
              <TableCell>
                    Cidade
              </TableCell>
              <TableCell>
                    Estado
              </TableCell>
              <TableCell>
                    Ações
              </TableCell> 
                </TableRow>
              </TableHead>

              <TableBody>
                {(customers)&&customers.slice(page * limit, page * limit + limit).map((customer, index) => (
                  <TableLinha oferta={customer} index={index} key={customer.id} />
                ))}
              </TableBody>

            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={customers.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>





      <Dialog
        open={openEdit||openAdicionar}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth='lg'
      >
        <DialogContent>
          <Container maxWidth={'xl'}>
            <Formik
              initialValues={userEdit}
              onSubmit={async (values, { setSubmitting }) => {
                if (openAdicionar) {
                  const resultado = await DataStore.save(
                    new NewsLetter(values)
                  );
                } else {

                const resultado = await DataStore.save(NewsLetter.copyOf(userEdit, (updated) => {

                  for (var obj in values) {
                    const valor = values[obj]
                    console.log(`Teste ${testObject(valor)} ${obj} ${valor}`)
                    if (!testObject(valor)) {
                        updated[obj] = valor;
                    }
                  }
                }));
              }

                handleClose();
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box mb={3}>
                    <Typography color="textPrimary" variant="h2">
                      {`Editar Inscrito ${values.nome}`}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    >
                    </Typography>
                  </Box>
                  <TextField
                    error={Boolean(touched.nome && errors.nome)}
                    fullWidth
                    helperText={touched.nome && errors.nome}
                    label="Nome"
                    margin="normal"
                    name="nome"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.nome}
                    variant="outlined"
                  />
                  <TextField
                    fullWidth
                    label="E-mail"
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    variant="outlined"
                  />
                  <TextField
                    fullWidth
                    label="Telefone"
                    margin="normal"
                    name="telefone"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.telefone}
                    variant="outlined"
                  />
                  <TextField
                    fullWidth
                    label="Empresa"
                    margin="normal"
                    name="empresa"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.empresa}
                    variant="outlined"
                  />
                  <TextField
                    fullWidth
                    label="Cidade"
                    margin="normal"
                    name="cidade"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.cidade}
                    variant="outlined"
                  />
                  <TextField
                    fullWidth
                    label="Estado"
                    margin="normal"
                    name="estado"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.estado}
                    variant="outlined"
                  />
                  <Box my={2}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Salvar
                    </Button>
                  </Box>
                  <Box my={2}>
                    <Button
                      color="primary"
                      fullWidth
                      size="large"
                      variant="contained"
                      onClick={handleClose}
                    >
                      Cancelar
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        </DialogContent>
      </Dialog>
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default Results;