import React, { useState } from 'react';
import {
    Box,
    Container,
    makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Example from './example'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const BusinessListView = () => {
    const classes = useStyles();

    return (
        <Page
            className={classes.root}
            title="Business"
        >
            <DndProvider backend={HTML5Backend}>
                <Example />
            </DndProvider>
        </Page>
    );
};

export default BusinessListView;
