import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import Amplify from "aws-amplify";
import { DataStore } from 'aws-amplify';
import config from './aws-exports';

Amplify.configure(config);
DataStore.configure(config);

// export function setToken(userToken) {
//   sessionStorage.setItem('token', JSON.stringify(userToken));
// }

// export function getToken() {
//   const tokenString = sessionStorage.getItem('token');
//   const userToken = JSON.parse(tokenString);
//   return userToken?.token
// }

// function init(onsuccessresponse){
//   if(sessionStorage.getItem('token') === null) {
//    sessionStorage.setItem('token',JSON.stringify(onsuccessresponse));
//   }
//  }
 
 function getData(){
   if(localStorage.getItem('token') !== null) {
     var user = JSON.parse(localStorage.getItem('token'));
     if (user?.token != null) {
       return true;
     } else {
       return false;
     }
     
  }
 }

const App = () => {
  const isLoggedIn = getData();
  const routing = useRoutes(routes(isLoggedIn));

  return (
    <ThemeProvider theme={theme}>
        <GlobalStyles />
        {routing}
    </ThemeProvider>
  );
};

export default App;
