import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import update from "immutability-helper";
import * as Yup from "yup";
//import * as Util from '../../utils/utils'
import * as Data from "../../utils/data";
import * as Utils from "../../utils/utils";
import SimpleImageSlider from "react-simple-image-slider";
import { Formik } from "formik";
import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  CardContent,
  Checkbox,
  FormHelperText,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TextField,
  InputAdornment,
  SvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Collapse,
  makeStyles
} from "@material-ui/core";
import { Search as SearchIcon } from "react-feather";
import getInitials from "src/utils/getInitials";
import { DataStore, SortDirection } from "aws-amplify";
import { CotacaoRegiao, Cotacoes, Demand, Produtos } from "../../models";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import axios from "axios";
//const tabletojson = require('tabletojson').Tabletojson;
//import { Tabletojson } from 'tabletojson';

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const Results = ({ className, customers, ...rest }) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAdicionar, setOpenAdicionar] = useState(false);
  const [openMulti, setOpenMulti] = useState(false);
  const [pushUser, setPushUser] = useState();
  const [userEdit, setUserEdit] = useState();
  const [cotacoesRegiao, setCotacoesRegiao] = useState([]);

  const [openAdicionarCotacao, setOpenAdicionarCotacao] = useState(false);
  const [openEditCotacao, setOpenEditCotacao] = useState(false);
  const [regiaoEdit, setRegiaoEdit] = useState();
  const [cotacaoEdit, setCotacaoEdit] = useState();
  const [cotacaoModelo, setCotacaoModelo] = useState();
  const [cotacaoData, setCotacaoData] = useState();
  const [refresh, setRefresh] = useState();

  // const [customers, setCustomers] = useState(data);
  const [values, setValues] = React.useState({
    title: "",
    body: "",
    data: {
      data: ""
    }
  });

  async function getCotacoes() {
    let retorno = {};

    for (var obj in customers) {
      const element = customers[obj];

      const quotes = await DataStore.query(Cotacoes, c =>
        c.cotacaoregiaoID("eq", element.id), {
          sort: (s) => s.dataCotacao(SortDirection.DESCENDING),
          limit: 10
        }
      );
      const propriedade = element.id;
      retorno[propriedade] = quotes;
    }

    setCotacoesRegiao(retorno);
  }

  async function getCotacoesIbrafe() {

    async function salvarCotacao(cote, dados) {
      const valores = {
        regiao: cote.regiao,
        cotacaoregiaoID: cote.id,
        dataCotacao: new Date().toISOString(),
        produtos: Produtos.FEIJAO,
        data: JSON.stringify(dados)
      };
      const resultado = await DataStore.save(new Cotacoes(valores));
      return resultado;
    }

    await fetch('https://5qeyyxd3ti.execute-api.us-east-1.amazonaws.com/default/agrify-ibrafe-parser',
    {
      //mode: "no-cors",
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
      // method: "GET",
      // headers: {
      //   Accept: "*/*",
      //   "Accept-encoding": "gzip, deflate",
      //   "Connection": "keep-alive",
      //   "Access-Control-Allow-Origin": "*"
      // },
    })
      .then(function(response){
        //console.log(response)
        return response.json();
      })
      .then(async function(myJson) {
        console.log(JSON.stringify(customers));

        for (const key in myJson) {
          const regiaoCota = myJson[key];
          const regiaoAchado = customers.find(
            element => ((element.regiao.toLowerCase()).includes(regiaoCota.titulo.toLowerCase()))
          );

          if (regiaoAchado) {
          console.log(JSON.stringify(regiaoCota), JSON.stringify(regiaoAchado));
          await salvarCotacao(regiaoAchado, regiaoCota.dados);
          }
        }

        alert('Atualizado')
        return myJson;
      })
      .catch((erro) => {
        console.log(erro);
      })


  }

  useEffect(async () => {
    const subscription3 = DataStore.observe(Cotacoes).subscribe(msg => {
      getCotacoes();
    });
    getCotacoes();
    return () => {
      subscription3.unsubscribe();
    };
  }, [customers]);

  useEffect(() => {
    getCotacoes();

    return () => {};
  }, [refresh]);

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenMulti(false);
    setOpenEdit(false);
    setOpenAdicionar(false);
    setOpenAdicionarCotacao(false);
    setOpenEditCotacao(false);
    setCotacaoData([]);
  };

  const handleSelectAll = event => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = customers.map(customer => customer.id);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(1)
      );
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };


  const handleMostrarQuote = async (cota, valor) => {
    
    console.log(`handleMostrarQuote ${JSON.stringify(cota)} ${valor}`);

    const resultado = await DataStore.save(
      Cotacoes.copyOf(cota, updated => {
        updated.mostrar = valor;
      })
    );

  };

  const handleLimitChange = event => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  function formatPhoneNumber(phoneNumberString) {
    var numberPattern = /\d+/g;
    var match = phoneNumberString.match(numberPattern);
    return `+55${match}`;
  }

  async function ApagarLinha(id) {
    const modelToDelete = await DataStore.query(CotacaoRegiao, id);

    if (
      window.confirm(
        `Deseja apagar a regiao ${modelToDelete.regiao} selecionada?`
      )
    ) {
      if (
        window.confirm(
          `Comfirma a exclusão de ${modelToDelete.regiao} ?\nEssa operação não pode ser desfeita.`
        )
      ) {
        const sucesso = DataStore.delete(modelToDelete);
        if (sucesso) {
          alert("Usuário excluído com sucesso");
          setSelectedCustomerIds([]);
        }
      }
    }
  }

  function testObject(valor) {
    return valor != null && typeof valor === "object";
  }

  function testVar(valor) {
    return valor != null && typeof valor === undefined;
  }

  async function ApagarCotacao(cote) {
    console.log("APAGAR: " + JSON.stringify(cote));
    const modelToDelete = DataStore.delete(cote);
    setRefresh(Math.random());
    return modelToDelete;
  }

  async function EditarCotacao(cote) {
    setCotacaoModelo(cote);
    setCotacaoEdit(cote);
    setCotacaoData(JSON.parse(cote.data));
    setOpenEditCotacao(true);
  }

  async function DuplicarCotacao(cote) {
    setCotacaoEdit({ ...cote, id: "", dataCotacao: new Date().toISOString() });
    setCotacaoData(JSON.parse(cote.data));
    setOpenAdicionarCotacao(true);
  }

  const handleChangeCotacao = idx => e => {
    const { name, value } = e.target;
    const rows = [...cotacaoData];
    rows[idx] = {
      ...rows[idx],
      [name]: value
    };
    setCotacaoData(rows);
  };

  function changeArray(funcao, arr, index, valor) {
    const rows = [...arr];
    rows[index] = valor;

    funcao(rows);
  }

  function CotacoesCell(props)
  {
    const { data } = props;
    const linha = data;

    return (
        <div>
          {linha.map((ln, index) => (
                    <div key={index}>{`${ln.titulo} (${Utils.currencyFormat(ln.valor)}) ${(ln.variacao)?ln.variacao:""}`}</div>
                  ))}
        </div>
    )
  }

  function TableLinha(props) {
    const { oferta, index } = props;
    const [cotacoes, setCotacoes] = useState([]);
    const [open, setOpen] = React.useState(false);

    useEffect(async () => {
      if (cotacoesRegiao[oferta.id]) {
        setCotacoes(cotacoesRegiao[oferta.id]);
      }
      return () => {};
    }, [cotacoesRegiao[oferta.id]]);

    return (
      <React.Fragment>
        <TableRow
          hover
          key={oferta.id}
          selected={selectedCustomerIds.indexOf(oferta.id) !== -1}
        >
          <TableCell padding="checkbox">
            <Checkbox
              checked={selectedCustomerIds.indexOf(oferta.id) !== -1}
              onChange={event => handleSelectOne(event, oferta.id)}
              value="true"
            />
          </TableCell>

          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{oferta.regiao}</TableCell>

          <TableCell>{oferta.indice}</TableCell>

          <TableCell>{oferta.fonte}</TableCell>

          <TableCell>{oferta.produtos}</TableCell>

          <TableCell>
            {moment(oferta.criadoData).format("DD/MM/YYYY")}
          </TableCell>

          <TableCell>
            <Checkbox
              checked={oferta?.mostrar}
              onChange={(event, valor) => handleMostrarQuote(oferta, valor)}
              //value={true}
            />
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Box
                //display="flex" justifyContent="flex-end" maxWidth={500}
                //mt={3}
                >
                  <Typography variant="h6" gutterBottom component="div">
                    {`COTAÇÕES DE ${oferta.regiao.toUpperCase()}`}
                  </Typography>
                  <Button
                    color="primary"
                    variant="contained"
                    //disabled={selectedCustomerIds.length != 1}
                    onClick={() => {
                      setCotacaoEdit({
                        regiao: oferta.regiao,
                        data: "",
                        //fonte: "",
                        cotacaoregiaoID: oferta.id,
                        dataCotacao: new Date().toISOString(),
                        produtos: Produtos.FEIJAO
                      });
                      setCotacaoData([{
                        titulo: "Carioca Nota 9",
                        valor: 300.0,
                        variacao: null
                      }]);
                      setOpenAdicionarCotacao(true);
                    }}
                  >
                    Adicionar
                  </Button>
                </Box>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Data da Cotação</TableCell>
                      <TableCell>Região</TableCell>
                      <TableCell>Cotação</TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {cotacoes.map(historyRow => (
                      <TableRow key={historyRow.id}>
                        <TableCell component="th" scope="row">
                          {moment(historyRow.dataCotacao).format("DD/MM/YYYY")}
                        </TableCell>

                        <TableCell>{historyRow.regiao}</TableCell>

                        <TableCell>
                          <CotacoesCell data={historyRow?.data} />
                        </TableCell>

                        <TableCell align="right">
                          <Button
                            className={classes.importButton}
                            onClick={() => {
                              EditarCotacao(historyRow);
                            }}
                          >
                            Editar
                          </Button>
                          <Button
                            className={classes.importButton}
                            onClick={() => {
                              ApagarCotacao(historyRow);
                            }}
                          >
                            Apagar
                          </Button>
                          <Button
                            className={classes.importButton}
                            onClick={() => {
                              DuplicarCotacao(historyRow);
                            }}
                          >
                            Duplicar
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <div>
      <div>
        <Box>
          <Card>
            <CardContent>
              <div
                style={{
                  flexDirection: "row",
                  alignContent: "center",
                  justifyContent: "center"
                }}
              >
                {/* <Box>
                  <TextField
                    fullWidth
                    // maxWidth={500}
                    style={{ flex: 1 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    placeholder="Search customer"
                    variant="outlined"
                  />
                </Box> */}
                <Box
                //display="flex" justifyContent="flex-end" maxWidth={500}
                //mt={3}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.exportButton}
                    disabled={selectedCustomerIds.length != 1}
                    onClick={() => {
                      const found = customers.find(
                        element => element.id == selectedCustomerIds[0]
                      );
                      setUserEdit(found);
                      setOpenEdit(true);
                    }}
                  >
                    Editar
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.exportButton}
                    disabled={selectedCustomerIds.length != 1}
                    onClick={() => {
                      ApagarLinha(selectedCustomerIds[0]);
                    }}
                  >
                    Apagar
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.exportButton}
                    //disabled={selectedCustomerIds.length != 1}
                    onClick={() => {
                      setUserEdit({
                        regiao: "",
                        indice: "",
                        fonte: "",
                        criadoData: new Date().toISOString(),
                        //"cotacoes": [],
                        produtos: Produtos.FEIJAO
                      });
                      setOpenAdicionar(true);
                    }}
                  >
                    Adicionar
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    //disabled={selectedCustomerIds.length != 1}
                    onClick={async () => {
                      if (window.confirm("Atualizar com as cotações do dia?")) {
                        getCotacoesIbrafe();
                      }
                    }}
                  >
                    Atualizar Cotações
                  </Button>
                </Box>
              </div>
            </CardContent>
          </Card>
        </Box>
      </div>
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedCustomerIds.length === customers.length}
                      color="primary"
                      indeterminate={
                        selectedCustomerIds.length > 0 &&
                        selectedCustomerIds.length < customers.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell />
                  <TableCell>Região</TableCell>
                  <TableCell>Índice</TableCell>
                  <TableCell>Fonte</TableCell>
                  <TableCell>Produto</TableCell>
                  <TableCell>Inserido</TableCell>
                  <TableCell>Mostrar</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {customers
                  .slice(page * limit, page * limit + limit)
                  .map((customer, index) => (
                    <TableLinha
                      oferta={customer}
                      index={index}
                      key={customer.id}
                    />
                  ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={customers.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>

      <Dialog
        open={openEdit || openAdicionar}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <Container maxWidth="sm">
            <Formik
              initialValues={userEdit}
              validationSchema={Yup.object().shape({
                // email: Yup.string()
                //   .email("Must be a valid email")
                //   .max(255)
                //   .required("Email is required")
                // nome: Yup.string().max(255).required('First name is required'),
                // lastName: Yup.string().max(255).required('Last name is required'),
                // password: Yup.string().max(255).required('password is required'),
                // policy: Yup.boolean().oneOf([true], 'This field must be checked')
              })}
              onSubmit={async (values, { setSubmitting }) => {
                if (openAdicionar) {
                  const resultado = await DataStore.save(
                    new CotacaoRegiao(values)
                  );

                  handleClose();
                } else {
                  const resultado = await DataStore.save(
                    CotacaoRegiao.copyOf(userEdit, updated => {
                      for (var obj in values) {
                        const valor = values[obj];
                        console.log(
                          `Teste ${testObject(valor)} ${obj} ${valor}`
                        );
                        if (!testObject(valor)) {
                          if (obj == "nota") {
                            updated[obj] = parseFloat(valor);
                          } else {
                            updated[obj] = valor;
                          }
                        }
                      }
                    })
                  );

                  handleClose();
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box mb={3}>
                    <Typography color="textPrimary" variant="h2">
                      {`Editar ou Iserir Região de Cotação`}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    ></Typography>
                  </Box>
                  {/* "regiao": "",
                        "indice": "",
                        "fonte": "",
                        "criadoData": new Date().toISOString(),
                        //"cotacoes": [],
                        "produtos": Produtos.FEIJAO */}
                  <TextField
                    error={Boolean(touched.regiao && errors.regiao)}
                    fullWidth
                    helperText={touched.regiao && errors.regiao}
                    label="Região"
                    margin="normal"
                    name="regiao"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.regiao}
                    variant="outlined"
                    type="text"
                  />
                  <TextField
                    error={Boolean(touched.indice && errors.indice)}
                    fullWidth
                    helperText={touched.indice && errors.indice}
                    label="Índice"
                    margin="normal"
                    name="indice"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.indice}
                    variant="outlined"
                    type="text"
                  />
                  <TextField
                    error={Boolean(touched.fonte && errors.fonte)}
                    fullWidth
                    helperText={touched.fonte && errors.fonte}
                    label="Fonte"
                    margin="normal"
                    name="fonte"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.fonte}
                    variant="outlined"
                    type="text"
                  />

                  <Checkbox
                    error={Boolean(touched.mostrar && errors.mostrar)}
                    fullWidth
                    helperText={touched.mostrar && errors.mostrar}
                    label="Mostrar"
                    margin="normal"
                    name="mostrar"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    checked={values.mostrar}
                    //value={values.mostrar}
                    variant="outlined"
                    type="text"
                  />

                  <Box my={2}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Salvar
                    </Button>
                  </Box>
                  <Box my={2}>
                    <Button
                      color="primary"
                      fullWidth
                      size="large"
                      variant="contained"
                      onClick={handleClose}
                    >
                      Cancelar
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openEditCotacao || openAdicionarCotacao}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <Container maxWidth="sm">
            <Formik
              initialValues={cotacaoEdit}
              validationSchema={Yup.object().shape({})}
              onSubmit={async (values, { setSubmitting }) => {
                if (openAdicionarCotacao) {
                  const valores = {
                    ...values,
                    data: JSON.stringify(cotacaoData)
                  };

                  const resultado = await DataStore.save(new Cotacoes(valores));

                  handleClose();
                } else if (openEditCotacao) {
                  //const cotacaoCopy = { ...cotacaoEdit, data: JSON.stringify(cotacaoEdit.data) }
                  const resultado = await DataStore.save(
                    Cotacoes.copyOf(cotacaoModelo, updated => {
                      for (var obj in values) {
                        const valor = values[obj];
                        // console.log(
                        //   `Teste ${testObject(valor)} ${obj} ${valor}`
                        // );

                        //if (testObject(valor)) {
                        if (obj == "data") {
                          //updated[obj] = JSON.stringify(valor);
                          // }
                        } else {
                          updated[obj] = valor;
                        }
                      }

                      if (cotacaoData.length > 0) {
                        updated["data"] = JSON.stringify(cotacaoData);
                      }
                    })
                  );

                  handleClose();
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box mb={3}>
                    <Typography color="textPrimary" variant="h2">
                      {`Editar ou Iserir de Cotação`}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    ></Typography>
                  </Box>

                  <TextField
                    error={Boolean(touched.regiao && errors.regiao)}
                    fullWidth
                    helperText={touched.regiao && errors.regiao}
                    label="Região"
                    margin="normal"
                    name="regiao"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.regiao}
                    variant="outlined"
                    type="text"
                  />

                  <TextField
                    error={Boolean(touched.dataCotacao && errors.dataCotacao)}
                    fullWidth
                    helperText={touched.dataCotacao && errors.dataCotacao}
                    label="Data da Cotação"
                    margin="normal"
                    name="dataCotacao"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.dataCotacao}
                    variant="outlined"
                    type="text"
                  />

                  <Box>
                    <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      onClick={() => {
                        const iadd = {
                          titulo: "Carioca Nota ",
                          valor: 220,
                          variacao: null
                        };
                        const rows = [...cotacaoData, iadd];
                        setCotacaoData(rows);
                      }}
                    >
                      Adicionar
                    </Button>
                    <Button
                      style={{ marginLeft: 10 }}
                      color="primary"
                      size="small"
                      variant="contained"
                      onClick={() => {
                        let rows = cotacaoData.slice(0, cotacaoData.length - 1);
                        setCotacaoData(rows);
                      }}
                    >
                      Remover
                    </Button>
                  </Box>

                  {cotacaoData.map((quote, index) => (
                    <Box>
                      <TextField
                        style={{ alignSelf: "stretch" }}
                        label="Descrição"
                        margin="normal"
                        name="titulo"
                        //onBlur={handleBlur}
                        onChange={handleChangeCotacao(index)}
                        value={quote.titulo}
                        variant="outlined"
                        type="text"
                      />
                      <TextField
                        style={{ width: 80, marginRight: 10, marginLeft: 10 }}
                        label="Valor"
                        margin="normal"
                        name="valor"
                        //onBlur={handleBlur}
                        onChange={handleChangeCotacao(index)}
                        value={quote.valor || " "}
                        variant="outlined"
                        type="text"
                      />
                      <TextField
                        style={{ width: 60 }}
                        label="Trend"
                        margin="normal"
                        name="variacao"
                        //onBlur={handleBlur}
                        onChange={handleChangeCotacao(index)}
                        value={quote.variacao || " "}
                        variant="outlined"
                        type="text"
                      />
                      {/* {edited&&<Button
            style={{alignSelf: "center"}}
            color="primary"
            size="small"
            variant="contained"
            onClick={() => {
              if (onChange) onChange(objeto);
              setEdited(false)
            }}
          >
            OK
          </Button>} */}
                    </Box>
                  ))}

                  <Box my={2}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Salvar
                    </Button>
                  </Box>
                  <Box my={2}>
                    <Button
                      color="primary"
                      fullWidth
                      size="large"
                      variant="contained"
                      onClick={handleClose}
                    >
                      Cancelar
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        </DialogContent>
      </Dialog>
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default Results;
