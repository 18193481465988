import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import data from './data';
import { DataStore, SortDirection } from 'aws-amplify';
import { Chat, 
          UserAgrify,
          Offer,
          CotacaoRegiao,
          Cotacoes,
          Demand
         } from '../../models';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CotacoesListView = () => {
  const classes = useStyles();
  const [ofertas, setOfertas] = useState([]);

  async function fetchDataUsuarios() {
    const oferta = await DataStore.query(CotacaoRegiao, (c) => { }, {
      sort: (s) => s.regiao(SortDirection.ASCENDING)
    });
    setOfertas(oferta);
  }

  useEffect(() => {
    const subscription3 = DataStore.observe(CotacaoRegiao).subscribe((msg) => {
      fetchDataUsuarios();
    });

    fetchDataUsuarios();

    return () => {
      subscription3.unsubscribe();
    };
  }, []);



  return (
    <Page
      className={classes.root}
      title="Clientes"
    >
      <Container maxWidth={false}>
        {/* <Toolbar /> */}
        <Box mt={3}>
          {ofertas && <Results customers={ofertas} />}
        </Box>
      </Container>
    </Page>
  );
};

export default CotacoesListView;
