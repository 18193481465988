import { DataStore, SortDirection } from "aws-amplify";
import {
  Chat,
  UserAgrify,
  Offer,
  Demand,
  Produtos,
  Notifications,
  NotificationsType,
  ProdutoVariedade
} from "../models";
import * as WhatsApp from '../utils/whatsapp';



export async function enviarPush(id, titulo, mensagem, buss, type) {
    let sucesso;

    const message = {
      id: id,
      message: {
        title: titulo,
        body: mensagem,
        data: {
          type: type,
          data: buss
        }
      }
    };

    console.log(JSON.stringify(message));

    sucesso = await fetch(
      `https://u61gxy1z1j.execute-api.us-east-1.amazonaws.com/default/agrify-push-notification?id=${id}`,
      {
        mode: "no-cors",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Accept-encoding": "gzip, deflate",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(message)
      }
    );
  }



  export async function enviarEmail(email, template, objeto) {
    let sucesso;

    const message = {
        "Records": [
          {
            "body": {
              "email": email,
              "type": template,
              "payload": objeto
            }
          }
        ]
    }

    console.log(`${email} ${template} ${JSON.stringify(message)}`);

    sucesso = await fetch(
      `https://lp9qkcgh0b.execute-api.us-east-1.amazonaws.com/default/agrify-send-email`,
      {
        mode: "no-cors",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Accept-encoding": "gzip, deflate",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(message)
      }
    );
  }


  export async function salvarNotificacao(pushID, senderID, titulo, mensagem, oferta, negocio, telefone ) {

    const modelo = new Notifications({
      "title": titulo,
      "message": mensagem,
      "url": null,
      "data": JSON.stringify(getNotificationData(pushID, oferta, negocio, senderID)),
      "senderID": senderID,
      "useragrifyID": pushID,
      "criadoData": new Date().toISOString(),
      "type": NotificationsType.NEGOCIO,
      "status": null
    });
    const notificacao = await DataStore.save(modelo);

    if (telefone) {
      await WhatsApp.sendMessage(telefone, `${titulo}! ${mensagem}`);
    }

  }

  function getNotificationData(pushID, oferta, negocio, senderID) {
    return {
      type: NotificationsType.CHAT,
      negocio: negocio,
      ChatID: negocio.id,
      formKey: Math.random,
      oferta: oferta,
      ofertaID: oferta.id,
      //userChat: userChat,
      userChatID: senderID,
      userID: pushID
    }
  }