import React, { useState, useRef, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  CardContent,
  Checkbox,
  FormHelperText,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TextField,
  InputAdornment,
  SvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles
} from "@material-ui/core";
import { Search as SearchIcon } from "react-feather";
import getInitials from "src/utils/getInitials";
import { DataStore, SortDirection } from "aws-amplify";
import { Chat, UserAgrify, Offer, Demand } from "../../../models";
import * as API from "../../../utils/api";
import * as WhatsApp from '../../../utils/whatsapp';
import Mustache from "mustache";

import JoditEditor from "jodit-react";
import { ContactsOutlined } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const Results = ({ className, dados, ...rest }) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(200);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openMulti, setOpenMulti] = useState(false);
  const [openMultiEmail, setOpenMultiEmail] = useState(false);
  const [pushUser, setPushUser] = useState();
  const [userEdit, setUserEdit] = useState();
  const [values, setValues] = React.useState({
    title: "",
    body: "",
    data: {
      data: ""
    }
  });
  const [search, setSearch] = useState();
  const [customers, setCustomers] = useState(dados);
  const [wmessage, setWmessage] = useState();
  const [openWhats, setOpenWhats] = useState(false);


  // value={search}
  //                   onChange={(event) => {
  //                     setSearch(event.target.value);
  //                   }}

  useEffect(() => {
    if ((!search)||(search == '')||(typeof(search)==undefined))
    {
      console.log('inicio')
      setCustomers(dados);
    } else {
      console.log('fora')
      const query = dados?.filter(el => (((el?.nome?.toLowerCase()).includes(search.toLowerCase())) ||
                                        ((el?.empresa?.nome?.toLowerCase()).includes(search.toLowerCase())) ||
                                        ((el?.cidade?.toLowerCase()).includes(search.toLowerCase())) ||
                                        ((el?.estado?.toLowerCase()).includes(search.toLowerCase())) ||
                                        ((el?.email?.toLowerCase()).includes(search.toLowerCase()))
                                        ));
      setCustomers(query);
    }
    return () => {
    }
  }, [search, dados])

  const editor = useRef(null)
  const [content, setContent] = useState('')
  const config = {
    readonly: false // all options from https://xdsoft.net/jodit/doc/
  }

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenMulti(false);
    setOpenMultiEmail(false);
    setOpenEdit(false);
    setOpenWhats(false);
  };

  const handleSelectAll = event => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = customers.map(customer => customer.id);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(1)
      );
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = event => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };


  async function getUser(id) {
    const user =  await customers.find(
      element => element.id == id
    );
    return user;
  }

  async function enviarEmailMulti(msg) {
    for (var obj in selectedCustomerIds) {
      const sendID = selectedCustomerIds[obj];
      let sucesso;
      const usuario = await getUser(sendID);
      console.log(usuario.email);

      const mensagem = {
        ...usuario,
        ...msg
      }

      await API.enviarEmail(usuario.email, 'template_mensagem_usuario', mensagem);
    }
  }

  async function enviarPushMulti() {
    for (var obj in selectedCustomerIds) {
      const sendID = selectedCustomerIds[obj];
      let sucesso;

      const message = {
        id: sendID,
        message: values
      };

      console.log(JSON.stringify(message));

      sucesso = await fetch(
        `https://u61gxy1z1j.execute-api.us-east-1.amazonaws.com/default/agrify-push-notification?id=${sendID}`,
        {
          mode: "no-cors",
          method: "POST",
          headers: {
            Accept: "application/json",
            // 'Access-Control-Allow-Origin': '*',
            // "Access-Control-Allow-Methods": '*',
            "Accept-encoding": "gzip, deflate",
            "Content-Type": "application/json"
          },
          body: JSON.stringify(message)
        }
      );
    }
  }

  async function enviarPush() {
    if (window.confirm(`Comfirma o envio da mensagem para ${pushUser.nome}?`)) {
      let sucesso;

      const message = {
        id: pushUser.id,
        message: values
      };

      console.log(JSON.stringify(message));

      sucesso = await fetch(
        `https://u61gxy1z1j.execute-api.us-east-1.amazonaws.com/default/agrify-push-notification?id=${pushUser.id}`,
        {
          mode: "no-cors",
          method: "POST",
          headers: {
            Accept: "application/json",
            // 'Access-Control-Allow-Origin': '*',
            // "Access-Control-Allow-Methods": '*',
            "Accept-encoding": "gzip, deflate",
            "Content-Type": "application/json"
          },
          body: JSON.stringify(message)
        }
      );

      if (sucesso) {
        //alert('Usuário excluído com sucesso');
        setPushUser();
        setValues({
          title: "",
          body: "",
          data: {
            data: ""
          }
        });
      }
    }
  }

  async function ApagarLinha(id) {
    if (window.confirm("Deseja apagar o usuário selecionado?")) {
      const modelToDelete = await DataStore.query(UserAgrify, id);

      if (
        window.confirm(
          `Comfirma a exclusão de ${modelToDelete.nome} ${modelToDelete.telefone} de ${modelToDelete.cidade}/${modelToDelete.estado}?\nEssa operação não pode ser desfeita.`
        )
      ) {
        const sucesso = DataStore.delete(modelToDelete);
        if (sucesso) {
          alert("Usuário excluído com sucesso");
          setSelectedCustomerIds([]);
        }
      }
    }
  }

  function testObject(valor) {
    return ((valor != null) && (typeof (valor) === "object"));
  }

  function testVar(valor) {
    return ((valor != null) && (typeof (valor) === undefined));
  }

  const AsyncAlert = async (mensagem) => new Promise((resolve) => {
    if (window.confirm(mensagem)) {
      resolve(true);
    } else {
      resolve(false);
    }
  });

  async function enviarWhatsAppMulti() {
    const confirm = await AsyncAlert(`Comfirma o envio da mensagens?`);
    console.log('Confirm: ' + confirm);

    if (confirm) {
      for (var obj in selectedCustomerIds) {
        const offerID = selectedCustomerIds[obj];

        const found = await customers.find(
          element => element.id == offerID
        );

        if (!found) return;
      
        const telefone = found.telefone;

        const msg = Mustache.render(wmessage, found);
        const resposta = await WhatsApp.sendMessage(telefone, msg);
        console.log(resposta);
        
      }
    }
  }

  return (
    <div>
      <div>
        <Box>
          <Card>
            <CardContent>
              <div
                style={{
                  flexDirection: "row",
                  alignContent: "center",
                  justifyContent: "center"
                }}
              >
                <Box>
                  <TextField
                    fullWidth
                    // maxWidth={500}
                    style={{ flex: 1 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    placeholder="Procurar Cliente"
                    value={search}
                    onChange={(event) => {
                      setSearch(event.target.value);
                    }}
                    variant="outlined"
                  />
                </Box>
                <Box
                  //display="flex" justifyContent="flex-end" maxWidth={500}
                  mt={3}
                >
                  <Button
                    className={classes.importButton}
                    disabled={selectedCustomerIds.length < 1}
                    onClick={() => {
                      setOpenMultiEmail(true);
                    }}
                  >
                    Enviar E-mail
                  </Button>
                  <Button
                    className={classes.importButton}
                    disabled={selectedCustomerIds.length < 1}
                    onClick={() => {
                      setOpenMulti(true);
                    }}
                  >
                    Enviar Push
                  </Button>
                  <Button
                    className={classes.importButton}
                    disabled={selectedCustomerIds.length < 1}
                    onClick={() => {
                      setOpenWhats(true);
                    }}
                  >
                    Enviar WhatsApp
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.exportButton}
                    disabled={selectedCustomerIds.length != 1}
                    onClick={() => {
                      const found = customers.find(
                        element => element.id == selectedCustomerIds[0]
                      );
                      setUserEdit(found);
                      setOpenEdit(true);
                    }}
                  >
                    Editar
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={selectedCustomerIds.length != 1}
                    onClick={() => {
                      ApagarLinha(selectedCustomerIds[0]);
                    }}
                  >
                    Apagar
                  </Button>
                </Box>
              </div>
            </CardContent>
          </Card>
        </Box>
      </div>
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedCustomerIds.length === customers.length}
                      color="primary"
                      indeterminate={
                        selectedCustomerIds.length > 0 &&
                        selectedCustomerIds.length < customers.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Localidade</TableCell>
                  <TableCell>Celular</TableCell>
                  <TableCell>Empresa/Fazenda</TableCell>
                  <TableCell>Registration date</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {customers?.slice(page*limit, page*limit+limit).map(customer => (
                  <TableRow
                    hover
                    key={customer.id}
                    selected={selectedCustomerIds.indexOf(customer.id) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedCustomerIds.indexOf(customer.id) !== -1
                        }
                        onChange={event => handleSelectOne(event, customer.id)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        {(customer?.fotoUrl)&&<Avatar
                          className={classes.avatar}
                          src={customer?.fotoUrl}
                        >
                          {getInitials(customer.nome)}
                        </Avatar>}
                        {!(customer?.fotoUrl)&&<Avatar
                          className={classes.avatar}
                        >
                          {getInitials(customer?.nome)}
                        </Avatar>}
                        <Typography color="textPrimary" variant="body1">
                          {customer?.nome}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{customer?.email}</TableCell>
                    <TableCell>
                      {`${customer?.cidade}, ${customer?.estado}`}
                    </TableCell>
                    <TableCell>{customer?.telefone}</TableCell>
                    <TableCell>
                      {customer?.empresa?.nome || ""}
                    </TableCell>
                    <TableCell>
                      {moment(customer?.createdAt).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>
                      <div>
                        <Button
                          onClick={() => {
                            setPushUser(customer);
                            handleClickOpen();
                          }}
                          className={classes.exportButton}
                        >
                          Push
                        </Button>
                        <Button
                          onClick={event => {
                            handleSelectOne(event, customer.id);
                            setOpenWhats(true);
                          }}
                          color="primary"
                          variant="contained"
                        >
                          WhatsApp
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={customers.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>






      <Dialog
        open={open || openMulti}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Enviar Mensagem de Push
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Escreva aqui a mensagem enviada por push
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Titulo"
            type="text"
            value={values.title}
            onChange={handleChange("title")}
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Mensagem"
            multiline
            rows={4}
            value={values.body}
            onChange={handleChange("body")}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              if (openMulti) {
                enviarPushMulti();
              } else {
                enviarPush();
              }
              handleClose();
            }}
            color="primary"
          >
            Enviar
          </Button>
        </DialogActions>
      </Dialog>




      <Dialog
        open={openWhats}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Enviar Mensagem do Whats
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Você pode user {{nome}} {{empresa.nome}} {{email}} {{telefone}}`}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Mensagem"
            multiline
            rows={4}
            value={wmessage}
            onChange={(event) => {
              setWmessage(event.target.value);
            }}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              enviarWhatsAppMulti();
              handleClose();
            }}
            color="primary"
          >
            Enviar
          </Button>
        </DialogActions>
      </Dialog>






      <Dialog
        open={openEdit}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <Container maxWidth="sm">
            <Formik
              initialValues={userEdit}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Must be a valid email")
                  .max(255)
                  .required("Email is required")
                // nome: Yup.string().max(255).required('First name is required'),
                // lastName: Yup.string().max(255).required('Last name is required'),
                // password: Yup.string().max(255).required('password is required'),
                // policy: Yup.boolean().oneOf([true], 'This field must be checked')
              })}
              onSubmit={async (values, { setSubmitting }) => {
                const resultado = await DataStore.save(UserAgrify.copyOf(userEdit, (updated) => {
                  for (var obj in values) {
                    const valor = values[obj]
                    // console.log(`Teste ${testObject(valor)} ${obj} ${valor}`)
                    if (!testObject(valor)) {
                      updated[obj] = valor;
                    }
                  }
                }));
                handleClose();
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box mb={3}>
                    <Typography color="textPrimary" variant="h2">
                      {`Editar usuário ${values.nome}`}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    >
                      O telefone é o indice.
                    </Typography>
                  </Box>
                  <TextField
                    error={Boolean(touched.nome && errors.nome)}
                    fullWidth
                    helperText={touched.nome && errors.nome}
                    label="Nome"
                    margin="normal"
                    name="nome"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.nome}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.telefone && errors.telefone)}
                    fullWidth
                    helperText={touched.telefone && errors.telefone}
                    label="Telefone"
                    margin="normal"
                    name="telefone"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.telefone}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="E-mail"
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.email}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.cidade && errors.cidade)}
                    fullWidth
                    helperText={touched.cidade && errors.cidade}
                    label="Cidade"
                    margin="normal"
                    name="cidade"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.cidade}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.estado && errors.estado)}
                    fullWidth
                    helperText={touched.estado && errors.estado}
                    label="Estado"
                    margin="normal"
                    name="estado"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.estado}
                    variant="outlined"
                  />
                  <Box alignItems="center" display="flex">
                    {values.fotoUrl&&<Avatar className={classes.avatar} src={values.fotoUrl}>
                      {getInitials(values.nome)}
                    </Avatar>}
                    {!values.fotoUrl&&<Avatar className={classes.avatar}>
                      {getInitials(values.nome)}
                    </Avatar>}
                    <TextField
                    error={Boolean(touched.fotoUrl && errors.fotoUrl)}
                    fullWidth
                    helperText={touched.fotoUrl && errors.fotoUrl}
                    label="Endereço da Foto"
                    margin="normal"
                    name="fotoUrl"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.fotoUrl}
                    variant="outlined"
                  />
                  </Box>
                  {/* <Box alignItems="center" display="flex" ml={-1}>
                    <Checkbox
                      checked={values.policy}
                      name="policy"
                      onChange={handleChange}
                    />
                    <Typography color="textSecondary" variant="body1">
                      I have read the{" "}
                      <Link
                        color="primary"
                        component={RouterLink}
                        to="#"
                        underline="always"
                        variant="h6"
                      >
                        Terms and Conditions
                      </Link>
                    </Typography>
                  </Box> */}
                  <Box my={2}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Salvar
                    </Button>
                  </Box>
                  <Box my={2}>
                    <Button
                      color="primary"
                      fullWidth
                      size="large"
                      variant="contained"
                      onClick={handleClose}
                    >
                      Cancelar
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        </DialogContent>
      </Dialog>














      <Dialog
        open={openMultiEmail}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth='lg'
      >
        <DialogContent>
          <Container maxWidth={'xl'}>
            <Formik
              initialValues={
                {
                  titulo: '',
                  mensagem: '',
                }
              }
              validationSchema={Yup.object().shape({
              })}
              onSubmit={async (values, { setSubmitting }) => {
                const mensagem = {
                  titulo: values.titulo,
                  mensagem: content
                }

                enviarEmailMulti(mensagem);
                handleClose();
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box mb={3}>
                    <Typography color="textPrimary" variant="h2">
                      {`Enviar E-mail`}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    >
                    </Typography>
                  </Box>
                  <TextField
                    error={Boolean(touched.titulo && errors.titulo)}
                    fullWidth
                    helperText={touched.titulo && errors.titulo}
                    label="Título"
                    margin="normal"
                    name="titulo"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.titulo}
                    variant="outlined"
                  />
                  <JoditEditor
                    heigth={500}
                    ref={editor}
                    value={content}
                    config={config}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                    onChange={newContent => { }}
                  />
                  <Box my={2}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Enviar
                    </Button>
                  </Box>
                  <Box my={2}>
                    <Button
                      color="primary"
                      fullWidth
                      size="large"
                      variant="contained"
                      onClick={handleClose}
                    >
                      Cancelar
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        </DialogContent>
      </Dialog>
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default Results;

{
  /* export declare class UserAgrify {
  readonly id: string;
  readonly nome?: string;
  readonly email?: string;
  readonly telefone?: string;
  readonly cidade?: string;
  readonly estado?: string;
  readonly endereco?: string;
  readonly fotoUrl?: string;
  readonly ofertas?: (Offer | null)[];
  readonly demandas?: (Demand | null)[];
  readonly pushDevices?: (PushDevices | null)[];
  readonly userID?: string;
  readonly empresa?: UserEmpresa;
  readonly banco?: UserBanco;
  readonly contabil?: UserContabil;
  readonly config?: UserConfig;
  readonly notifications?: (Notifications | null)[];
  readonly cpf?: string;
  readonly assinatura?: UserAssinatura;
  readonly criadoData?: string;
  constructor(init: ModelInit<UserAgrify>);
  static copyOf(source: UserAgrify, mutator: (draft: MutableModel<UserAgrify>) => MutableModel<UserAgrify> | void): UserAgrify; */
}
