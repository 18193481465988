import React, { memo, useState, useCallback, useEffect } from "react";
import { NativeTypes } from "react-dnd-html5-backend";
import DemandaBox from "./DemandaBox";
import Box from "./Box";
import { ItemTypes } from "./ItemTypes";
import update from "immutability-helper";
// import FlatList from 'flatlist-react';
// import "intersection-observer";
// import { ScrollView } from "@cantonjs/react-scroll-view";
import {
  Box as Boxa,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  makeStyles,
  colors,
  TextField
} from "@material-ui/core";
import ScrollMenu from "react-horizontal-scrolling-menu";
import styled, { css } from "styled-components";
import MatchList from "./MatchList";
import data from "./data";
import { DataStore, SortDirection } from "aws-amplify";
import {
  Chat,
  UserAgrify,
  Offer,
  Demand,
  Produtos,
  Notifications,
  NotificationsType,
  ProdutoVariedade
} from "../../models";
import { objectOf } from "prop-types";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import * as API from "../../utils/api";

import { DialogProvider, useDialog } from "react-async-dialog"
import MatchListDemanda from "./MatchListDemanda";
import MatchListOferta from "./MatchListOferta";


export const Container = memo(function Container() {
  const [ofertas, setOfertas] = useState([]);
  const [demandas, setDemandas] = useState([]);
  const [negocios, setNegocios] = useState([]);
  const [demandaUser, setDemandaUser] = useState([]);
  const [ofertaUser, setOfertaUser] = useState([]);
  const dialog = useDialog();
  const taxStd = 2.0;

  useEffect(async () => {
    let retorno = {};

    for (var obj in demandas) {
      const element = demandas[obj];
      const user = await DataStore.query(UserAgrify, c => c.id("eq", element.useragrifyID));
      const propriedade = element.id;
      retorno[propriedade] = user[0];
    }

    setDemandaUser(retorno);

    return () => {};
  }, [demandas]);

  useEffect(async () => {
    let retorno = {};

    for (var obj in ofertas) {
      const element = ofertas[obj];
      const user = await DataStore.query(UserAgrify, c => c.id("eq", element.useragrifyID));
      const propriedade = element.id;
      retorno[propriedade] = user[0];
    }

    setOfertaUser(retorno);

    return () => {};
  }, [ofertas]);

  async function fetchDataOffer() {
    const oferta = await DataStore.query(Offer, c => {}, {
      sort: (s) => s.criadoData(SortDirection.DESCENDING)
    });
    setOfertas(oferta);
  }

  async function fetchDataDemanda() {
    let demanda = await DataStore.query(Demand, c => {}, {
      sort: (s) => s.criadoData(SortDirection.DESCENDING)
    });
    setDemandas(demanda);
  }

  async function fetchDataNegocios() {
    const chats = await DataStore.query(Chat, c => {}, {
      sort: (s) => s.matchAt(SortDirection.DESCENDING)
    });
    setNegocios(chats);
  }

  useEffect(() => {
    const subscription = DataStore.observe(Offer).subscribe(msg => {
      //console.log(`Subscription: ${JSON.stringify(msg)}!`);
      fetchDataOffer();
    });

    const subscription2 = DataStore.observe(Demand).subscribe(msg => {
      //console.log(`Subscription: ${JSON.stringify(msg)}!`);
      fetchDataDemanda();
    });

    const subscription3 = DataStore.observe(Chat).subscribe(msg => {
      //console.log(`Subscription: ${JSON.stringify(msg)}!`);
      fetchDataNegocios();
    });

    fetchDataOffer();
    fetchDataDemanda();
    fetchDataNegocios();

    return () => {
      subscription.unsubscribe();
      subscription2.unsubscribe();
      subscription3.unsubscribe();
    };
  }, []);

  const [droppedBoxNames, setDroppedBoxNames] = useState([]);

  function isDropped(boxName) {
    return droppedBoxNames.indexOf(boxName) > -1;
  }

  function makeId(length) {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"; //'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }


  async function onMatch(index, item, oferta, demanda) {

    let tax;

    const ok = await dialog.confirm(<><div><TextField
      fullWidth
      //helperText="Please specify the first name"
      defaultValue={taxStd.toString()}
      label="Taxa da Agrify no Negócio (em %)"
      name="tax"
      onChange={(val) => {
        //console.log(val.target.value);
        tax = val.target.value;
        }}
      value={tax}
      variant="outlined"
    /></div>
    </>, {
      ok: "Confirmar",
      cancel: "Cancelar"
    })
    if (!ok) {
      return
    }

    const taxa = parseFloat(tax);

     if ((tax == null) || (taxa == null) || (typeof(tax) == undefined) || (tax == '')) {
       tax = taxStd;
     } 

    //console.log(taxa + ' ' + tax + ' ' + typeof(taxa) + ' ' + typeof(tax));
    
    const objeto = {
      status: "NEGOCIACAO",
      numero: makeId(8),
      offerID: oferta.id,
      demandID: demanda.id,
      sellerID: oferta.useragrifyID,
      buyerID: demanda.useragrifyID,
      tax: parseFloat(tax),
      matchAt: new Date().toISOString()
    };

    console.log(JSON.stringify(objeto));

    const newMatch = await DataStore.save(new Chat(objeto));

    if (newMatch) {
      if (window.confirm("Match criado com sucesso!")) console.log("Criado");

      //Enviar as notificações
      const seller = await DataStore.query(UserAgrify, (c) => c.id('eq', oferta.useragrifyID));
      //if (seller[0]) setSeller(seller[0]);

      const buyer = await DataStore.query(UserAgrify, (c) => c.id('eq', demanda.useragrifyID));
      //if (seller[0]) setSeller(seller[0]);


      //Enviar os Push
      await API.enviarPush(
        oferta.useragrifyID,
        `Nova negociação da oferta ${oferta.numero}`,
        `Você tem um interessado pela sua oferta. Entre no app Agrify para negociar.`,
        newMatch,
        'OFERTA'
      ); //Mensagem para o vendedor

      if (seller[0].email) {
        const mensagem = {
            "nome": seller[0].nome,
            "tipo": "oferta",
            "outro": "Demanda",
            "numero": demanda.numero,
            "produto": oferta.produto,
            "variedade": oferta.variedade,
            "quantidade": `${demanda.quantidade} sacas`
          }

        await API.enviarEmail(seller[0].email, 'template_negocio_match', mensagem);
      }

      await API.enviarPush(
        demanda.useragrifyID,
        `Nova negociação da demanda ${demanda.numero}`,
        `Encontramos uma oferta que possa ser de seu interesse. Entre no app Agrify para negociar.`,
        newMatch,
        'DEMANDA'
      ); //Mensagem para o comprador

      if (buyer[0].email) {
        const mensagem = {
            "nome": buyer[0].nome,
            "tipo": "demanda",
            "outro": "Oferta",
            "numero": oferta.numero,
            "produto": oferta.produto,
            "variedade": oferta.variedade,
            "quantidade": `${oferta.volume} sacas`
          }

        await API.enviarEmail(buyer[0].email, 'template_negocio_match', mensagem);
      }


      await API.salvarNotificacao(
            oferta.useragrifyID, 
            demanda.useragrifyID, 
            `Nova negociação da oferta ${oferta.numero}`,
            `Você tem um interessado pela sua oferta. Entre no app Agrify para negociar.`,
            oferta,
            newMatch,
            (seller[0].telefone?seller[0].telefone:null)
        );

      await API.salvarNotificacao(
        demanda.useragrifyID, 
        oferta.useragrifyID, 
        `Nova negociação da demanda ${demanda.numero}`,
        `Encontramos uma oferta que possa ser de seu interesse. Entre no app Agrify para negociar.`,
        oferta,
        newMatch,
        (buyer[0].telefone?buyer[0].telefone:null)
      )


    }

    const { name } = item;
    setDroppedBoxNames(
      update(droppedBoxNames, name ? { $push: [name] } : { $push: [] })
    );
    setDemandas(
      update(demandas, {
        [index]: {
          lastDroppedItem: {
            $set: name
          }
        }
      })
    );
  }

  function getTextMatch(oferta, demanda) {
    return `Tem certeza que deseja criar o match entre a OFERTA #${oferta.numero} e a DEMANDA #${demanda.numero} ?`;
  }

  const handleDrop = useCallback(
    (index, item) => {
      const oferta = item.oferta;
      const demanda = demandas[index];

      console.log(`${JSON.stringify(demanda)} ====> ${JSON.stringify(oferta)}`);

      if (window.confirm(getTextMatch(oferta, demanda)))
        onMatch(index, item, oferta, demanda);
    },
    [droppedBoxNames, demandas]
  );



  function DemandasItens(props) {
    const { demands } = props;

    return demands.map((demanda, index) => {
      //console.log('DEMANDA: ' + JSON.stringify(demanda))
      if (demanda.numero)
        return (
          <DemandaBox
            user={demandaUser[demanda.id]}
            demanda={demanda}
            accepts={[demanda.variedade]}
            lastDroppedItem={demanda.lastDroppedItem}
            onDrop={item => handleDrop(index, item)}
            key={index}
          />
        );
    })


  }

  function OfertasItens(props) {
    const { offers } = props;

    return offers.map((oferta, index) => {
      if (oferta.numero)
       //console.log(`Oferta: ${JSON.stringify(oferta)}`)
        return (
          <Box
            user={ofertaUser[oferta.id]}
            oferta={oferta}
            name={oferta.numero}
            type={(oferta.variedade?oferta.variedade:'Indefinido')}
            isDropped={isDropped(oferta.numero)}
            key={index}
          />
        );
    })


  }

  function renderDemanda(demands) {

    return demands.map((demanda, index) => {
      //console.log('DEMANDA: ' + JSON.stringify(demanda))
      if (demanda.numero)
        return (
          <DemandaBox
            user={demandaUser[demanda.id]}
            demanda={demanda}
            accepts={[demanda.variedade]}
            lastDroppedItem={demanda.lastDroppedItem}
            onDrop={item => handleDrop(index, item)}
            key={index}
          />
        );
    })


  }

  function renderOferta(offers) {
    
    return offers.map((oferta, index) => {
      if (oferta.numero)
       //console.log(`Oferta: ${JSON.stringify(oferta)}`)
        return (
          <Box
            user={ofertaUser[oferta.id]}
            oferta={oferta}
            name={oferta.numero}
            type={(oferta.variedade?oferta.variedade:'Indefinido')}
            isDropped={isDropped(oferta.numero)}
            key={index}
          />
        );
    })


  }


  return (
    <div style={{ marginLeft: 10, marginRight: 10 }}>
      <Card>
        <CardHeader
          action={
            <Button endIcon={<ArrowDropDownIcon />} size="small" variant="text">
              Ativas
            </Button>
          }
          title="Demandas Ativas"
        />
        <Divider />
        <CardContent>
          <Boxa
            //height={400}
            position="relative"
          >
            <div style={styleScroll}>
              <DemandasItens demands={demandas} />
            </div>
          </Boxa>
        </CardContent>
      </Card>

      <Card
        style={{ marginTop: 20 }}
      >
        <CardHeader
          action={
            <Button endIcon={<ArrowDropDownIcon />} size="small" variant="text">
              Ativas
            </Button>
          }
          title="Ofertas Ativas"
        />
        <Divider />
        <CardContent>
          <Boxa
            //height={400}
            position="relative"
          >
            <div style={styleScroll}>
              <OfertasItens offers={ofertas} />
            </div>
          </Boxa>
        </CardContent>
      </Card>

      {/* <MatchListDemanda
        style={{ marginTop: 20 }}
        titulo="Demandas Ativas"
        dados={negocios}
        render={renderDemanda}
      />

      <MatchListOferta
        style={{ marginTop: 20 }}
        titulo="Ofertas Ativos"
        dados={negocios}
        render={renderOferta}
      /> */}

      <MatchList
        style={{ marginTop: 20 }}
        titulo="Negócios Ativos"
        dados={negocios}
      />
    </div>
  );
});

const ScrollArea = styled.div`
  overflow-x: scroll;
  height: 226px;
  background-color: rgba(230, 230, 230, 1);
  right: 0px;
  flex-direction: row;
  display: flex;
`;

const styleScroll = {
  overflow: "hidden",
  clear: "both",
  flexDirection: "row",
  overflowX: "auto",
  height: 200,
  display: "flex",
  margin: 10,
  //backgroundColor: 'rgba(230, 230, 230,1)',
  scrollbarWidth: 20
};

const styleScrollVertical = {
  overflow: "hidden",
  clear: "both",
  flexDirection: "column",
  overflowX: "auto",
  height: 600,
  display: "flex",
  margin: 10,
  backgroundColor: "rgba(230, 230, 230,1)",
  scrollbarWidth: 20
};
