
  function openUrl(url) {
    const win = window.open(url, "_blank");
    if (win != null) {
      win.focus();
    }
  }

function formatPhoneNumber(phoneNumberString) {
    var numberPattern = /\d+/g;
    var match = phoneNumberString.match(numberPattern);
    return `+55${match}`;
  }


export async function sendMessage(phone, text) {
    // const url = `https://agrify.loca.lt/send?phone=${formatPhoneNumber(phone)}&text=${text}`;
    const textSend = encodeURI(text.replace(`${'\\n'}`,'\n'));
    //myString.replace(/ /g, '<br />');
    const url = `http://ec2-107-20-1-188.compute-1.amazonaws.com:60008/send?phone=${formatPhoneNumber(phone)}&text=${textSend}`;
    console.log(url);
    const sucesso = await fetch(url, {mode: 'no-cors'});
    return sucesso;
  }
