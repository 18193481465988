import React, { useState, useEffect, memo } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import update from 'immutability-helper';
import * as Yup from "yup";
//import * as Util from '../../utils/utils'
import * as Data from '../../utils/data';
import SimpleImageSlider from "react-simple-image-slider";
import { Formik } from "formik";
import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  CardContent,
  Checkbox,
  FormHelperText,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TextField,
  InputAdornment,
  SvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Collapse,
  makeStyles
} from "@material-ui/core";
import { Search as SearchIcon } from "react-feather";
import getInitials from "src/utils/getInitials";
import { DataStore, SortDirection } from "aws-amplify";
import { Chat, UserAgrify, Offer, Demand } from "../../models";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import * as WhatsApp from '../../utils/whatsapp';

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  spaceButton: {
    marginBottom: 10
  }

}));

const Results = ({ className, dados, ...rest }) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openMulti, setOpenMulti] = useState(false);
  const [pushUser, setPushUser] = useState();
  const [userEdit, setUserEdit] = useState();
  const [ofertaUsers, setOfertaUsers] = useState([]);

  // const [customers, setCustomers] = useState(data);
  const [values, setValues] = React.useState({
    title: "",
    body: "",
    data: {
      data: ""
    }
  });

  const [search, setSearch] = useState();
  const [customers, setCustomers] = useState(dados);


  // value={search}
  //                   onChange={(event) => {
  //                     setSearch(event.target.value);
  //                   }}
 //


  useEffect(() => {
    if ((!search)||(search == '')||(typeof(search)==undefined))
    {
      console.log('inicio')
      setCustomers(dados);
    } else {
      console.log('fora')
      const query = dados.filter(el => (((el?.numero?.toLowerCase()).includes(search.toLowerCase())) ||
                                        ((ofertaUsers[el?.id]?.user?.nome?.toLowerCase()).includes(search.toLowerCase()))
                                        // ((el.cidade)&&(el.cidade.toLowerCase()).includes(search.toLowerCase())) ||
                                        // ((el.estado)&&(el.estado.toLowerCase()).includes(search.toLowerCase())) ||
                                        // ((el.email)&&(el.email.toLowerCase()).includes(search.toLowerCase()))
                                        ));
      setCustomers(query);
    }
    return () => {
    }
  }, [search, dados])

  useEffect(async () => {
    
    let retorno = {}

    for (var obj in customers) {

      
      const element = customers[obj];
      let valor = {
        user: null,
        business: null,
      };

      if (element.useragrifyID != "00000000-0000-0000-0000-000000000000") {

        //console.log(JSON.stringify(element));

        const seller = await DataStore.query(UserAgrify, (c) => c.id('eq', element.useragrifyID));
        const business = await DataStore.query(Chat, (c) => c.offerID('eq', element.id));

        valor = {
          user: seller[0],
          business: business,
        };


      }

      const propriedade = element.id;

      retorno[propriedade] = valor;

    }

    setOfertaUsers(retorno);

    return () => {
    }
  }, [customers])

  function getOferta(id) {
    return dados.filter(element => element.id == id);
  }

  const handleClick = (objeto) => {
    window.open(`https://api.agrify.com.br/oferta?id=${objeto?.id}`);
  };

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenMulti(false);
    setOpenEdit(false);
  };

  const handleSelectAll = event => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = customers.map(customer => customer.id);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(1)
      );
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = event => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  function openUrl(url) {
    const win = window.open(url, "_blank");
    if (win != null) {
      win.focus();
    }
  }

  function formatPhoneNumber(phoneNumberString) {
    var numberPattern = /\d+/g;
    var match = phoneNumberString.match(numberPattern);
    return `+55${match}`;
  }


  async function enviarPushMulti() {
    for (var obj in selectedCustomerIds) {
      const offerID = selectedCustomerIds[obj];

      const found = customers.find(
        element => element.id == offerID
      );

      if (!found) return;
    
      const sendID = ofertaUsers[found.id].user.id;
      let sucesso;
      const message = {
        id: sendID,
        message: values
      };

      sucesso = await fetch(
        `https://u61gxy1z1j.execute-api.us-east-1.amazonaws.com/default/agrify-push-notification?id=${sendID}`,
        {
          mode: "no-cors",
          method: "POST",
          headers: {
            Accept: "application/json",
            // 'Access-Control-Allow-Origin': '*',
            // "Access-Control-Allow-Methods": '*',
            "Accept-encoding": "gzip, deflate",
            "Content-Type": "application/json"
          },
          body: JSON.stringify(message)
        }
      );
    }
  }

  const AsyncAlert = async (mensagem) => new Promise((resolve) => {
    if (window.confirm(mensagem)) {
      resolve(true);
    } else {
      resolve(false);
    }
  });
  

  async function enviarWhatsAppMulti() {
    for (var obj in selectedCustomerIds) {
      const offerID = selectedCustomerIds[obj];

      const found = await customers.find(
        element => element.id == offerID
      );

      if (!found) return;
    
      const telefone = ofertaUsers[found.id].user.telefone;

      //if (window.confirm(`Comfirma o envio da mensagem para ${ofertaUsers[found.id].user.nome} ${telefone}?`)) {
      const confirm = await AsyncAlert(`Comfirma o envio da mensagem para ${ofertaUsers[found.id].user.nome} ${telefone}?`);
      console.log('Confirm: ' + confirm);

      if (confirm) {
        const msg = `Olá ${ofertaUsers[found.id].user.nome} gostaríamos de conversar sobre a sua oferta ${found.numero} de ${moment(found.criadoData).format('DD/MM/YYYY')}, postada na Agrify. Estaria disponível? `;
        const resposta = await WhatsApp.sendMessage(telefone, msg);
        console.log(resposta);
      }


      // if (confirm) {
      // openUrl(
      //   `https://api.whatsapp.com/send?text=Olá ${ofertaUsers[found.id].user.nome} gostaríamos de conversar sobre a sua oferta ${found.numero} de ${moment(found.criadoData).format('DD/MM/YYYY')}, postada na Agrify. Estaria disponível? &phone=${formatPhoneNumber(
      //     telefone
      //   )}`
      // );
      // }
    }
  }


  // async function enviarPush() {
  //   if (window.confirm(`Comfirma o envio da mensagem para ${pushUser.nome}?`)) {
  //     let sucesso;

  //     const message = {
  //       id: pushUser.id,
  //       message: values
  //     };

  //     console.log(JSON.stringify(message));

  //     sucesso = await fetch(
  //       `https://u61gxy1z1j.execute-api.us-east-1.amazonaws.com/default/agrify-push-notification?id=${pushUser.id}`,
  //       {
  //         mode: "no-cors",
  //         method: "POST",
  //         headers: {
  //           Accept: "application/json",
  //           // 'Access-Control-Allow-Origin': '*',
  //           // "Access-Control-Allow-Methods": '*',
  //           "Accept-encoding": "gzip, deflate",
  //           "Content-Type": "application/json"
  //         },
  //         body: JSON.stringify(message)
  //       }
  //     );

  //     if (sucesso) {
  //       //alert('Usuário excluído com sucesso');
  //       setPushUser();
  //       setValues({
  //         title: "",
  //         body: "",
  //         data: {
  //           data: ""
  //         }
  //       });
  //     }
  //   }
  // }

  async function ApagarLinha(id) {
    const modelToDelete = await DataStore.query(Offer, id);

    let nomeVendedor = (ofertaUsers[id]?.user?.nome);

    if (!nomeVendedor) {
      const modelo =  getOferta(id);
      //console.log('MODELO', JSON.stringify(modelo));
      nomeVendedor = modelo[0]?.vendedor?.nome || "";
    }
    

    if (window.confirm(`Deseja apagar a oferta ${modelToDelete.numero} selecionada?`)) {

      if (
        window.confirm(
          `Comfirma a exclusão de ${modelToDelete.numero} inserida por ${nomeVendedor}?\nEssa operação não pode ser desfeita.`
        )
      ) {
        const sucesso = DataStore.delete(modelToDelete);
        if (sucesso) {
          alert("Usuário excluído com sucesso");
          setSelectedCustomerIds([]);
        }
      }
    }
  }

  function testObject(valor) {
    return ((valor != null) && (typeof (valor) === "object"));
  }

  function testVar(valor) {
    return ((valor != null) && (typeof (valor) === undefined));
  }


  function TableLinha(props) {
    const { oferta, index } = props;
    const [user, setUser] = useState({nome: 'Cadastrado API', fotoUrl: null});
    const [negocios, setNegocios] = useState([]);
    const [fotos, setFotos] = useState([]);
    const [open, setOpen] = React.useState(false);

    useEffect(async () => {
      if (ofertaUsers[oferta.id]) {
        if (ofertaUsers[oferta.id].user) {  
          setUser(ofertaUsers[oferta.id].user);
        } else {

          if (oferta?.vendedor?.nome) {
            setUser({nome: `${oferta?.vendedor?.nome} ${oferta?.vendedor?.telefone} (F)`, fotoUrl: null});
          }
        }
        if (ofertaUsers[oferta.id].business) setNegocios(ofertaUsers[oferta.id].business);


        if (oferta.fotos) {
          //console.log('FOTOS', JSON.stringify(oferta.fotos));
          const fotosTemp = oferta.fotos;
          let retorno = [];
          for (var obj in fotosTemp) {
            const url = fotosTemp[obj].uri;
            //console.log(obj + ' ' + url); 
            retorno.push({ url: url})
            //setFotos([{ url: url}, { url: url}]);
          }
          setFotos(retorno);
        }

      }
      return () => {
      }
    }, [ofertaUsers[oferta.id]])
  
    return (<React.Fragment>
      <TableRow
      hover
      key={oferta.id}
      selected={selectedCustomerIds.indexOf(oferta.id) !== -1}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={selectedCustomerIds.indexOf(oferta.id) !== -1}
          onChange={(event) => handleSelectOne(event, oferta.id)}
          value="true"
        />
      </TableCell>

      <TableCell>
          <IconButton aria-label="expand row" disabled={((!negocios) || (negocios.length<1))} size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
      </TableCell>

      <TableCell>
        { oferta.numero }
      </TableCell>

      <TableCell>
        {moment(oferta.criadoData).format('DD/MM/YYYY')}
      </TableCell>

      <TableCell>
        {user&&<Box
          alignItems="center"
          display="flex"
        >
          {user?.fotoUrl&&<Avatar
            className={classes.avatar}
            src={user?.fotoUrl}
          >
            {getInitials(user.nome)}
          </Avatar>}
          {!user?.fotoUrl&&<Avatar
            className={classes.avatar}
          >
            {getInitials(user?.nome)}
          </Avatar>}
          <Typography
            color="textPrimary"
            variant="body1"
          >
             {user?.nome}
          </Typography>
        </Box>}
      </TableCell>

      <TableCell>
        {`${Data.valueToText(oferta.produto)} (${Data.valueToText(oferta.variedade)})`}
      </TableCell>

      <TableCell>
        {`${oferta.volume}`}
      </TableCell>

      <TableCell>
        {moment(oferta.dataColheita).format('DD/MM/YYYY')}
      </TableCell>

      <TableCell>
        {`${oferta.nota}`}
      </TableCell>

      <TableCell>
        {`${negocios.length}`}
      </TableCell>

      <TableCell>
        {(fotos&&(fotos.length>0)) && <SimpleImageSlider
          width={150}
          height={100}
          showBullets={true}
          images={fotos}
        />}
      </TableCell>

      <TableCell>
        {`${oferta.status}`}
      </TableCell>

      <TableCell>
      <Button
          onClick={event => {
            handleClick(oferta);
          }}
          color="primary"
          variant="contained"
          className={classes.spaceButton}
        >
          VER
        </Button>
        <Button
          onClick={event => {
            //handleClick(oferta);
          }}
          color="primary"
          variant="contained"
        >
          EDITAR
        </Button>
      </TableCell>
    </TableRow>

    <TableRow>
    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
      <Collapse in={(open&&(negocios.length>0))} timeout="auto" unmountOnExit>
        <Box margin={1}>
          <Typography variant="h6" gutterBottom component="div">
            Negócios
          </Typography>
          <Table size="small" aria-label="purchases">
            <TableHead>
              <TableRow>
                <TableCell>Numero</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="right">Comprador</TableCell>
                <TableCell align="right">Data do Match</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {negocios.map((historyRow) => (
                <TableRow key={historyRow.numero}>
                  <TableCell component="th" scope="row">
                    {historyRow.numero}
                  </TableCell>
                  <TableCell>{historyRow.status}</TableCell>
                  <TableCell align="right">{historyRow.buyerID}</TableCell>
                  <TableCell align="right">
                    {moment(historyRow.matchAt).format('DD/MM/YYYY')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Collapse>
    </TableCell>
  </TableRow>
  </React.Fragment>
    );
  }




  return (
    <div>
      <div>
        <Box>
          <Card>
            <CardContent>
              <div
                style={{
                  flexDirection: "row",
                  alignContent: "center",
                  justifyContent: "center"
                }}
              >
                <Box>
                  <TextField
                    fullWidth
                    // maxWidth={500}
                    style={{ flex: 1 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    placeholder="Procurar Oferta"
                    value={search}
                    onChange={(event) => {
                      setSearch(event.target.value);
                    }}
                    variant="outlined"
                  />
                </Box>
                <Box
                  //display="flex" justifyContent="flex-end" maxWidth={500}
                  mt={3}
                >
                  <Button
                    className={classes.importButton}
                    disabled={selectedCustomerIds.length < 1}
                    onClick={() => {
                      enviarWhatsAppMulti();
                      //setOpenMulti(true);
                    }}
                  >
                    Enviar WhatsApp
                  </Button>
                  <Button
                    className={classes.importButton}
                    disabled={selectedCustomerIds.length < 1}
                    onClick={() => {
                      setOpenMulti(true);
                    }}
                  >
                    Enviar Push
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.exportButton}
                    disabled={selectedCustomerIds.length != 1}
                    onClick={() => {
                      const found = customers.find(
                        element => element.id == selectedCustomerIds[0]
                      );
                      setUserEdit(found);
                      setOpenEdit(true);
                    }}
                  >
                    Editar
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={selectedCustomerIds.length == 0}
                    onClick={() => {

                      for (var obj in selectedCustomerIds) {
                        const id = selectedCustomerIds[obj];
                        ApagarLinha(id);
                      }


                    }}
                  >
                    Apagar
                  </Button>
                </Box>
              </div>
            </CardContent>
          </Card>
        </Box>
      </div>
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table>

              <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectedCustomerIds.length === customers.length}
                  color="primary"
                  indeterminate={
                    selectedCustomerIds.length > 0
                    && selectedCustomerIds.length < customers.length
                  }
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell />
              <TableCell>
                Número
              </TableCell>
              <TableCell>
                Data de Cadastro
              </TableCell>
              <TableCell>
                Vendedor
              </TableCell>
              <TableCell>
                Produto
              </TableCell>
              <TableCell>
                Volume
              </TableCell>
              <TableCell>
                Disponibilidade
              </TableCell>
              <TableCell>
                Nota
              </TableCell>
              <TableCell>
                Negociações
              </TableCell>
              <TableCell>
                Fotos
              </TableCell>
              <TableCell>
                Status
              </TableCell>
              <TableCell>
                
              </TableCell>
            </TableRow>
          </TableHead>
          
          <TableBody>
            {customers.slice(page*limit, page*limit+limit).map((customer, index) => (
              <TableLinha oferta={customer} index={index} key={customer.id}/>
            ))}
          </TableBody>

            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={customers.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>




      <Dialog
        open={open || openMulti}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Enviar Mensagem de Push
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Escreva aqui a mensagem enviada por push
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Titulo"
            type="text"
            value={values.title}
            onChange={handleChange("title")}
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Mensagem"
            multiline
            rows={4}
            value={values.body}
            onChange={handleChange("body")}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              enviarPushMulti();
              handleClose();
            }}
            color="primary"
          >
            Enviar
          </Button>
        </DialogActions>
      </Dialog>





      <Dialog
        open={openEdit}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <Container maxWidth="sm">
            <Formik
              initialValues={userEdit}
              validationSchema={Yup.object().shape({
                // email: Yup.string()
                //   .email("Must be a valid email")
                //   .max(255)
                //   .required("Email is required")
                // nome: Yup.string().max(255).required('First name is required'),
                // lastName: Yup.string().max(255).required('Last name is required'),
                // password: Yup.string().max(255).required('password is required'),
                // policy: Yup.boolean().oneOf([true], 'This field must be checked')
              })}
              onSubmit={async (values, { setSubmitting }) => {

                const resultado = await DataStore.save(Offer.copyOf(userEdit, (updated) => {

                  for (var obj in values) {
                    const valor = values[obj]
                    console.log(`Teste ${testObject(valor)} ${obj} ${valor}`)
                    if (!testObject(valor)) {
                      if (obj == 'nota')
                      { 
                        updated[obj] = parseFloat(valor);
                      } else {
                      updated[obj] = valor;
                      }
                    }
                  }
                }));

                handleClose();
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box mb={3}>
                    <Typography color="textPrimary" variant="h2">
                      {`Editar oferta ${values.numero}`}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    >
                    </Typography>
                  </Box>
                  <TextField
                    error={Boolean(touched.volume && errors.volume)}
                    fullWidth
                    helperText={touched.volume && errors.volume}
                    label="Volume"
                    margin="normal"
                    name="volume"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.volume}
                    variant="outlined"
                    type="number"
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                  />
                  <TextField
                    error={Boolean(touched.nota && errors.nota)}
                    fullWidth
                    helperText={touched.nota && errors.nota}
                    label="Nota"
                    margin="normal"
                    name="nota"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.nota}
                    variant="outlined"
                  />
                  {/* <Box alignItems="center" display="flex" ml={-1}>
                    <Checkbox
                      checked={values.policy}
                      name="policy"
                      onChange={handleChange}
                    />
                    <Typography color="textSecondary" variant="body1">
                      I have read the{" "}
                      <Link
                        color="primary"
                        component={RouterLink}
                        to="#"
                        underline="always"
                        variant="h6"
                      >
                        Terms and Conditions
                      </Link>
                    </Typography>
                  </Box> */}
                  <Box my={2}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Salvar
                    </Button>
                  </Box>
                  <Box my={2}>
                    <Button
                      color="primary"
                      fullWidth
                      size="large"
                      variant="contained"
                      onClick={handleClose}
                    >
                      Cancelar
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        </DialogContent>
      </Dialog>
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default Results;