import React, { useEffect, useState } from "react";
import { Container, Grid, colors, makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import Budget from "./Budget";
import LatestOrders from "./LatestOrders";
import LatestProducts from "./LatestProducts";
import Sales from "./Sales";
import TasksProgress from "./TasksProgress";
import TotalCustomers from "./TotalCustomers";
import TotalProfit from "./TotalProfit";
import TrafficByDevice from "./TrafficByDevice";
import { DataStore, SortDirection } from "aws-amplify";
import { Chat, UserAgrify, Offer, Demand } from "../../../models";
import PeopleIcon from "@material-ui/icons/PeopleOutlined";
import BusinessCenterOutlinedIcon from "@material-ui/icons/BusinessCenterOutlined";
import ChatBubbleOutlineOutlinedIcon from "@material-ui/icons/ChatBubbleOutlineOutlined";
import SpaOutlinedIcon from "@material-ui/icons/SpaOutlined";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const offerID = "a3f4095e-39de-43d2-baf4-f8c16f0f6f4d";
  const demandID = useState(); // = "a3f4095e-39de-43d2-baf4-f8c16f0f6f4d";
  const [ofertas, setOfertas] = useState();
  const [demandas, setDemandas] = useState();
  const [usuarios, setUsuarios] = useState();
  const [negocios, setNegocios] = useState();

  async function fetchDataOffer() {
    // const chats = await DataStore.query(Chat, (c) => c.offerID('eq', offerID), {
    //   sort: (s) => s.matchAt(SortDirection.ASCENDING)
    // });

    // console.log(JSON.stringify(chats));

    const oferta = await DataStore.query(Offer, c => {}, {
      //sort: (s) => s.matchAt(SortDirection.ASCENDING)
    });

    //console.log(JSON.stringify(oferta));
    setOfertas(oferta);

    //const user = await DataStore.query(UserAgrify, (c) => c.id('eq', 'fcde5c78-ab7e-4404-86af-da0d1fab712c'));
    //console.log(`user: ${JSON.stringify(user)}!`);
  }

  async function fetchDataDemanda() {
    const demanda = await DataStore.query(Demand, c => {}, {
      //sort: (s) => s.matchAt(SortDirection.ASCENDING)
    });
    setDemandas(demanda);
  }

  async function fetchDataUsuarios() {
    const usuarios = await DataStore.query(UserAgrify, c => {}, {
      //sort: (s) => s.matchAt(SortDirection.ASCENDING)
    });
    setUsuarios(usuarios);

    const negocios = await DataStore.query(Chat, c => {}, {
      //sort: (s) => s.matchAt(SortDirection.ASCENDING)
    });
    setNegocios(negocios);
  }

  useEffect(() => {
    // const subscription = DataStore.observe(Chat, (c) => c.or(
    //   (d) => d.offerID('eq', offerID)
    // ).or(
    //   (e) => e.demandID('eq', demandID)
    // )).subscribe((msg) => {
    //   console.log(`Subscription: ${JSON.stringify(msg)}!`);
    // });

    const subscription = DataStore.observe(Offer).subscribe(msg => {
      //console.log(`Subscription: ${JSON.stringify(msg)}!`);
      fetchDataOffer();
    });

    const subscription2 = DataStore.observe(Demand).subscribe(msg => {
      //console.log(`Subscription: ${JSON.stringify(msg)}!`);
      fetchDataDemanda();
    });

    const subscription3 = DataStore.observe(UserAgrify).subscribe(msg => {
      //console.log(`Subscription: ${JSON.stringify(msg)}!`);
      fetchDataUsuarios();
    });

    fetchDataOffer();
    fetchDataDemanda();
    fetchDataUsuarios();

    return () => {
      subscription.unsubscribe();
      subscription2.unsubscribe();
      subscription3.unsubscribe();
    };
  }, []);

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            {/* <Budget /> */}
            <TotalCustomers
              titulo={"DEMANDAS"}
              valor={demandas ? demandas.length : 0}
              Icone={BusinessCenterOutlinedIcon}
              cor={colors.red[600]}
              variacao={null}
              variacaoDesc={null}
            />
          </Grid>

          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalCustomers
              titulo={"OFERTAS"}
              valor={ofertas ? ofertas.length : 0}
              Icone={SpaOutlinedIcon}
              cor={colors.green[600]}
            />
          </Grid>

          <Grid item lg={3} sm={6} xl={3} xs={12}>
            {/* <Budget /> */}
            <TotalCustomers
              titulo={"USUÁRIOS"}
              valor={usuarios ? usuarios.length : 0}
              cor={colors.orange[600]}
            />
          </Grid>

          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalCustomers
              titulo={"NEGOCIAÇÕES"}
              valor={negocios ? negocios.length : 0}
              Icone={ChatBubbleOutlineOutlinedIcon}
              cor={colors.yellow[600]}
            />
          </Grid>

          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TasksProgress />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalProfit />
          </Grid>

          {/* <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <Budget />
            <TotalCustomers titulo={'USUÁRIOS'} valor={usuarios?usuarios.length:0} />
          </Grid>

          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <TotalCustomers titulo={'NEGOCIAÇÕES'} valor={negocios?negocios.length:0} />
          </Grid> */}

          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TasksProgress />
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TotalProfit />
          </Grid>

          <Grid item lg={8} md={12} xl={9} xs={12}>
            <Sales />
          </Grid>
          <Grid item lg={4} md={6} xl={3} xs={12}>
            <TrafficByDevice />
          </Grid>
          {/* {demandas && <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <LatestProducts data={demandas}/>
          </Grid>}
          {ofertas && <Grid
            item
            lg={8}
            md={12}
            xl={9}
            xs={12}
          >
            <LatestOrders data={ofertas}/>
          </Grid>} */}
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
