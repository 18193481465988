import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import { DataStore, SortDirection, Predicates } from 'aws-amplify';
import {   EmailTemplates  } from '../../models';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const TemplatesListView = () => {
  const classes = useStyles();
  const [templates, setTemplates] = useState();

  async function fetchDataUsuarios() {
    const models = await DataStore.query(EmailTemplates, Predicates.ALL, {
      sort: (s) => s.type(SortDirection.ASCENDING)
    });
    //console.log(JSON.stringify(models));
    setTemplates(models);
  }

  useEffect(() => {
    const subscription3 = DataStore.observe(EmailTemplates).subscribe((msg) => {
      fetchDataUsuarios();
    });
    fetchDataUsuarios();
    return () => {
      subscription3.unsubscribe();
    };
  }, []);



  return (
    <Page
      className={classes.root}
      title="Templates de Email"
    >
      <Container maxWidth={false}>
        {/* <Toolbar /> */}
        <Box mt={3}>
          {templates&&<Results customers={templates} />}
        </Box>
      </Container>
    </Page>
  );
};

export default TemplatesListView;
