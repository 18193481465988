import currency from "currency.js"

function formatMoney(amount, decimalCount = 2, decimal = ",", thousands = ".") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-R$" : "R$";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.log(e)
  }
};

export function currencyFormat(num) {
  //var BRL = value => currency(value, { symbol: "R$", separator: ".", decimal: ",", precision: 2 });
    //return BRL(num).format();

    /*var formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
      
      return formatter.format(num);*/

      return formatMoney(num);
}

export function currencyToFloat(currency) {

  let number = parseFloat(currency.replace(/\D/g, "")/100);

  //console.log('currency: ' + currency + ' ' + number)
  //let number = Number(currency.replace(/[^0-9.-]+/g,""));
  //console.log("---->"+ numeroString);
  //return parseFloat(numeroString.substring(2, numeroString.length)).toFixed(2);
  return parseFloat(number).toFixed(2);

}