import React, { memo, useState, useEffect } from "react";
import { DropTarget } from "react-dnd";
import moment from "moment";
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles
} from "@material-ui/core";
import { DataStore, SortDirection } from "aws-amplify";
import {
  Chat,
  UserAgrify,
  Offer,
  Demand,
  Produtos,
  ProdutoVariedade
} from "../../models";
import getInitials from "src/utils/getInitials";

const style = {
  height: "12rem",
  width: "12rem",
  minWidth: "12rem",
  marginRight: "1.5rem",
  marginBottom: "1.5rem",
  border: "1px solid gray",
  color: "white",
  padding: "1rem",
  textAlign: "center",
  fontSize: "1rem",
  lineHeight: "normal",
  float: "left"
};

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

export const DemandaBox = memo(function DemandaBox({
  demanda,
  accepts,
  isOver,
  user,
  canDrop,
  connectDropTarget,
  lastDroppedItem
}) {

  const classes = useStyles();

  const isActive = isOver && canDrop;
  let backgroundColor = "#004d30";
  if (isActive) {
    backgroundColor = "darkgreen";
  } else if (canDrop) {
    backgroundColor = "#90bf44";
  }
  // eslint-disable-next-line
  return connectDropTarget(
    <div
      ref={connectDropTarget}
      style={{ ...style, backgroundColor }}
      role="Dustbin"
    >
      {isActive ? "Solte para fazer o match" : `${accepts.join(", ")}`}
      <div style={{ textAlign: "left" }}>
        <p>Demanda: {demanda.numero}</p>
        <p>Quantidade: {demanda.quantidade}</p>
        <p>Nota: {demanda.nota}</p>
        <p>
          Periodo:{" "}
          {`${moment(demanda.dataInicio).format("DD/MM")} - ${moment(
            demanda.dataFinal
          ).format("DD/MM")}`}
        </p>
        <p>Preço: {`R$ ${demanda.valorDesejado.toFixed(2)}`}</p>

        {user&&<div style={{marginTop: 5}}><Box
            alignItems="center"
            display="flex"
          >
            {/* <Avatar
              className={classes.avatar}
              src={user.fotoUrl}
            >
              {getInitials(user.nome)}
            </Avatar> */}


            {user.fotoUrl&&<Avatar
              className={classes.avatar}
              src={user.fotoUrl}
            >
              {getInitials(user.nome)}
            </Avatar>}

            {!user.fotoUrl&&<Avatar
              className={classes.avatar}
            >
              {getInitials(user.nome)}
            </Avatar>}



            {/* <Typography
              color="white"
              variant="body1"
            > */}
            <div style={{fontSize: 13}}>
               {`${user.nome}\n${user.cidade} ${user.estado}`}
            </div>
            {/* </Typography> */}
          </Box>
        </div>}

        {/* <p>Comprador: {`${demanda.useragrifyID}`}</p> */}
        {lastDroppedItem && (
          <p>Último Match: {JSON.stringify(lastDroppedItem)}</p>
        )}
      </div>
    </div>
  );
});

export default DropTarget(
  props => props.accepts,
  {
    drop(props, monitor) {
      //console.log('Monitor: ' + JSON.stringify(monitor.getItem()));
      props.onDrop(monitor.getItem());
    }
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  })
)(DemandaBox);
