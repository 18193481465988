import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import * as Yup from "yup";
//import * as Util from '../../utils/utils'
import JoditEditor from "jodit-react";
import { Formik } from "formik";
import AWS from 'aws-sdk'
import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  CardContent,
  Checkbox,
  FormHelperText,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TextField,
  InputAdornment,
  SvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Collapse,
  makeStyles
} from "@material-ui/core";
import { Search as SearchIcon } from "react-feather";
import { DataStore, SortDirection } from "aws-amplify";
import { EmailTemplates } from "../../models";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_ACCESS_KEY
})



const Results = ({ className, customers, ...rest }) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openMulti, setOpenMulti] = useState(false);
  const [pushUser, setPushUser] = useState();
  const [userEdit, setUserEdit] = useState();
  const [ofertaUsers, setOfertaUsers] = useState([]);


  const editor = useRef(null)
  const [content, setContent] = useState('')

  const config = {
    readonly: false // all options from https://xdsoft.net/jodit/doc/
  }

  const myBucket = new AWS.S3({
    params: { Bucket: process.env.REACT_APP_BUCKET_NAME},
    region: process.env.REACT_APP_REGION,
  })

  // const [customers, setCustomers] = useState(data);
  const [values, setValues] = React.useState({
    title: "",
    body: "",
    data: {
      data: ""
    }
  });

  const uploadFile = (file, name, type) => {
    const params = {
      ACL: 'public-read',
      Key: name,
      ContentType: type,
      Body: file,
    }
    myBucket.putObject(params)
      .on('httpUploadProgress', (evt) => {
        // that's how you can keep track of your upload progress
        //console.log( Math.round((evt.loaded / evt.total) * 100));
      })
      .send((err) => {
         if (err) {
           // handle the error here
         }
      })
  }

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClose = () => {
    setOpen(false);
    setOpenMulti(false);
    setOpenEdit(false);
  };

  const handleSelectAll = event => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = customers.map(customer => customer.id);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(1)
      );
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = event => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  function openUrl(url) {
    const win = window.open(url, "_blank");
    if (win != null) {
      win.focus();
    }
  }

  function formatPhoneNumber(phoneNumberString) {
    var numberPattern = /\d+/g;
    var match = phoneNumberString.match(numberPattern);
    return `+55${match}`;
  }


  async function enviarPushMulti() {
    for (var obj in selectedCustomerIds) {
      const offerID = selectedCustomerIds[obj];

      const found = customers.find(
        element => element.id == offerID
      );

      if (!found) return;

      const sendID = ofertaUsers[found.id].user.id;
      let sucesso;
      const message = {
        id: sendID,
        message: values
      };

      sucesso = await fetch(
        `https://u61gxy1z1j.execute-api.us-east-1.amazonaws.com/default/agrify-push-notification?id=${sendID}`,
        {
          mode: "no-cors",
          method: "POST",
          headers: {
            Accept: "application/json",
            // 'Access-Control-Allow-Origin': '*',
            // "Access-Control-Allow-Methods": '*',
            "Accept-encoding": "gzip, deflate",
            "Content-Type": "application/json"
          },
          body: JSON.stringify(message)
        }
      );
    }
  }


  function enviarWhatsAppMulti() {
    // for (var obj in selectedCustomerIds) {
    //   const offerID = selectedCustomerIds[obj];

    //   const found = customers.find(
    //     element => element.id == offerID
    //   );

    //   if (!found) return;

    //   const telefone = ofertaUsers[found.id].user.telefone;

    //   if (window.confirm(`Comfirma o envio da mensagem para ${ofertaUsers[found.id].user.nome} ${telefone}?`)) {

    //   openUrl(
    //     `https://api.whatsapp.com/send?text=Olá ${ofertaUsers[found.id].user.nome} gostaríamos de conversar sobre a sua oferta ${found.numero} de ${moment(found.criadoData).format('DD/MM/YYYY')}, postada na Agrify. Estaria disponível? &phone=${formatPhoneNumber(
    //       telefone
    //     )}`
    //   );
    //   }
    // }
  }



  async function ApagarLinha(id) {
    const modelToDelete = await DataStore.query(EmailTemplates, id);

    if (window.confirm(`Deseja apagar templete ${modelToDelete.type} selecionada?`)) {

      if (
        window.confirm(
          `Comfirma a exclusão de ${modelToDelete.type}?\nEssa operação não pode ser desfeita.`
        )
      ) {
        const sucesso = DataStore.delete(modelToDelete);
        if (sucesso) {
          alert("Usuário excluído com sucesso");
          setSelectedCustomerIds([]);
        }
      }
    }
  }

  function testObject(valor) {
    return ((valor != null) && (typeof (valor) === "object"));
  }

  function testVar(valor) {
    return ((valor != null) && (typeof (valor) === undefined));
  }


  function TableLinha(props) {
    const { oferta, index } = props;
    const [user, setUser] = useState({ nome: 'Usuario Agrify', fotoUrl: null });
    const [negocios, setNegocios] = useState([]);
    // const [fotos, setFotos] = useState([]);
    const [open, setOpen] = React.useState(false);

    useEffect(async () => {
      if (ofertaUsers[oferta.id]) {
        setUser(ofertaUsers[oferta.id].user);
        setNegocios(ofertaUsers[oferta.id].business);

        // if (oferta.fotos) {
        //   const fotosTemp = JSON.parse(oferta.fotos);
        //   let retorno = [];
        //   for (var obj in fotosTemp) {
        //     const url = fotosTemp[obj].uri;
        //     //console.log(obj + ' ' + url); 
        //     retorno.push({ url: url})
        //     //setFotos([{ url: url}, { url: url}]);
        //   }
        //   setFotos(retorno);
        // }

      }
      return () => {
      }
    }, [ofertaUsers[oferta.id]])

    return (<React.Fragment><TableRow
      hover
      key={oferta.id}
      selected={selectedCustomerIds.indexOf(oferta.id) !== -1}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={selectedCustomerIds.indexOf(oferta.id) !== -1}
          onChange={(event) => handleSelectOne(event, oferta.id)}
          value="true"
        />
      </TableCell>

      <TableCell>
        <IconButton aria-label="expand row" disabled={((!negocios) || (negocios.length < 1))} size="small" onClick={() => setOpen(!open)}>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell>

      <TableCell>
        {oferta.type}
      </TableCell>

      {/* <TableCell>
        {oferta.file}
      </TableCell> */}

      <TableCell>
        {oferta.subject}
      </TableCell>

      <TableCell>
          <Button
            color="primary"
            variant="contained"
            className={classes.exportButton}
            //disabled={selectedCustomerIds.length != 1}
            onClick={() => {
              const found = oferta;

              setUserEdit(found);
              setOpenEdit(true);

              if (found.type) {

              fetch(`https://agrify-app.s3.amazonaws.com/email-templates/${found.type}.html`)
              .then(res => res.text())
              .then(data => {
                //console.log(data);
                setContent(data);
                // setUserEdit(found);
                // setOpenEdit(true);
              })
              .catch(err => {
                //throw err;
                setContent('');
                // setUserEdit(found);
                // setOpenEdit(true);
              });
            }

            }}
          >
            Editar
          </Button>
      </TableCell>


    </TableRow>

      <TableRow>
        {/* <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
      <Collapse in={(open&&(negocios.length>0))} timeout="auto" unmountOnExit>
        <Box margin={1}>
          <Typography variant="h6" gutterBottom component="div">
            Negócios
          </Typography>
          <Table size="small" aria-label="purchases">
            <TableHead>
              <TableRow>
                <TableCell>Numero</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="right">Vendedor</TableCell>
                <TableCell align="right">Data do Match</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {negocios.map((historyRow) => (
                <TableRow key={historyRow.numero}>
                  <TableCell component="th" scope="row">
                    {historyRow.numero}
                  </TableCell>
                  <TableCell>{historyRow.status}</TableCell>
                  <TableCell align="right">{historyRow.sellerID}</TableCell>
                  <TableCell align="right">
                    {moment(historyRow.matchAt).format('DD/MM/YYYY')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Collapse>
    </TableCell> */}
      </TableRow>
    </React.Fragment>);
  }




  return (
    <div>
      <div>
        <Box>
          <Card>
            <CardContent>
              <div
                style={{
                  flexDirection: "row",
                  alignContent: "center",
                  justifyContent: "center"
                }}
              >
                <Box>
                  <TextField
                    fullWidth
                    // maxWidth={500}
                    style={{ flex: 1 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    placeholder="Procurar Template"
                    variant="outlined"
                  />
                </Box>
                <Box
                  //display="flex" justifyContent="flex-end" maxWidth={500}
                  mt={3}
                >
                  {/* <Button
                    className={classes.importButton}
                    disabled={selectedCustomerIds.length < 1}
                    onClick={() => {
                      enviarWhatsAppMulti();
                      //setOpenMulti(true);
                    }}
                  >
                    Enviar WhatsApp
                  </Button>
                  <Button
                    className={classes.importButton}
                    disabled={selectedCustomerIds.length < 1}
                    onClick={() => {
                      setOpenMulti(true);
                    }}
                  >
                    Enviar Push
                  </Button> */}
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.exportButton}
                    //disabled={selectedCustomerIds.length != 1}
                    onClick={() => {

                      const found = new EmailTemplates({
                        "type": 'template_',
                        "file": 'template_',
                        "subject": '[Agrify] ',
                        "criadoData": (new Date()).toISOString()
                      })

                      setUserEdit(found);
                      setOpenEdit(true);
                      const file = null; //'template_rascunho.html'

                      if (file) {
                      fetch(`https://agrify-app.s3.amazonaws.com/email-templates/${file}`, {mode: 'no-cors'})
                      .then(res => res.text())
                      .then(data => {
                        setContent(data);

                      })
                      .catch(err => {
                        setContent('');
                      });
                    }
                    
                    }}
                  >
                    Novo
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.exportButton}
                    disabled={selectedCustomerIds.length != 1}
                    onClick={() => {
                      const found = customers.find(
                        element => element.id == selectedCustomerIds[0]
                      );
                      setUserEdit(found);
                      setOpenEdit(true);

                      if (found.type) {

                      fetch(`https://agrify-app.s3.amazonaws.com/email-templates/${found.type}.html`)
                      .then(res => res.text())
                      .then(data => {
                        //console.log(data);
                        setContent(data);
                        // setUserEdit(found);
                        // setOpenEdit(true);
                      })
                      .catch(err => {
                        //throw err;
                        setContent('');
                        // setUserEdit(found);
                        // setOpenEdit(true);
                      });
                    }

                    }}
                  >
                    Editar
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={selectedCustomerIds.length != 1}
                    onClick={() => {
                      ApagarLinha(selectedCustomerIds[0]);
                    }}
                  >
                    Apagar
                  </Button>
                </Box>
              </div>
            </CardContent>
          </Card>
        </Box>
      </div>
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table>

              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedCustomerIds.length === customers.length}
                      color="primary"
                      indeterminate={
                        selectedCustomerIds.length > 0
                        && selectedCustomerIds.length < customers.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>
                  </TableCell>
                  <TableCell>
                    Type
              </TableCell>
                  {/* <TableCell>
                    File
              </TableCell> */}
                  <TableCell>
                    Subject
              </TableCell>
              <TableCell>
                    Ações
              </TableCell> 
                </TableRow>
              </TableHead>

              <TableBody>
                {customers.slice(page * limit, page * limit + limit).map((customer, index) => (
                  <TableLinha oferta={customer} index={index} key={customer.id} />
                ))}
              </TableBody>

            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={customers.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>




      <Dialog
        open={open || openMulti}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Enviar Mensagem de Push
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Escreva aqui a mensagem enviada por push
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Titulo"
            type="text"
            value={values.title}
            onChange={handleChange("title")}
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Mensagem"
            multiline
            rows={4}
            value={values.body}
            onChange={handleChange("body")}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              enviarPushMulti();
              handleClose();
            }}
            color="primary"
          >
            Enviar
          </Button>
        </DialogActions>
      </Dialog>





      <Dialog
        open={openEdit}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth='lg'
      >
        <DialogContent>
          <Container maxWidth={'xl'}>
            <Formik
              initialValues={userEdit}
              validationSchema={Yup.object().shape({
              })}
              onSubmit={async (values, { setSubmitting }) => {

                const resultado = await DataStore.save(EmailTemplates.copyOf(userEdit, (updated) => {

                  updated.file = `${values.type}.html`;
                  updated.subject = values.subject;
                  updated.type = values.type;

                  // for (var obj in values) {
                  //   const valor = values[obj]
                  //   console.log(`Teste ${testObject(valor)} ${obj} ${valor}`)
                  //   if (!testObject(valor)) {
                  //     if (obj == 'file') {
                  //       updated[obj] = `${values['type']}.html`;
                  //     } else {
                  //       updated[obj] = valor;
                  //     }
                  //   }
                  // }
                }));

                if (resultado) {
                  console.log(JSON.stringify(resultado));
                  const newFileName = `email-templates/${values.type}.html`;
                  uploadFile(content, newFileName, "text/html");
                  handleClose();
                }

              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box mb={3}>
                    <Typography color="textPrimary" variant="h2">
                      {`Editar Template ${values.type}`}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    >
                    </Typography>
                  </Box>
                  <TextField
                    error={Boolean(touched.type && errors.type)}
                    fullWidth
                    helperText={touched.type && errors.type}
                    label="Type"
                    margin="normal"
                    name="type"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.type}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.subject && errors.subject)}
                    fullWidth
                    helperText={touched.subject && errors.subject}
                    label="Subject"
                    margin="normal"
                    name="subject"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.subject}
                    variant="outlined"
                  />
                  <JoditEditor
                    heigth={500}
                    ref={editor}
                    value={content}
                    config={config}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                    onChange={newContent => { }}
                  />
                  <Box my={2}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Salvar
                    </Button>
                  </Box>
                  <Box my={2}>
                    <Button
                      color="primary"
                      fullWidth
                      size="large"
                      variant="contained"
                      onClick={handleClose}
                    >
                      Cancelar
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        </DialogContent>
      </Dialog>
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default Results;