import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  LocalMall,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcon,
  Package,
  MessageCircle,
  DollarSign,
  Grid,
  Mail,
  Tag,
  Award
} from 'react-feather';
import NavItem from './NavItem';
import NavItemSub from './NavItemSub';

const user = {
  avatar: 'https://agrify-app.s3.amazonaws.com/clientes/9717ff77-8943-4148-9299-3801c4d7ec87/fotos/ea9ebf42-a93a-4d3a-86cf-f08d727e6161.jpg',
  jobTitle: 'CEO',
  name: 'Higor Fernandes'
};

const items = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard'
  },
  {
    href: '/app/customers',
    icon: UsersIcon,
    title: 'Clientes',
    items: [
      {
        href: '/app/customersList',
        icon: UsersIcon,
        title: 'Lista'
      }
    ]
  },
  {
    href: '/app/ofertas',
    icon: Package,
    title: 'Ofertas'
  },
  {
    href: '/app/demandas',
    icon: ShoppingBagIcon,
    title: 'Demandas'
  },
  {
    href: '/app/business',
    icon: MessageCircle,
    title: 'Negócios'
  },
  {
    href: '/app/pedidos',
    icon: Award,
    title: 'Fechados'
  },
  {
    href: '/app/classificacoes',
    icon: Tag,
    title: 'Classificações'
  },
  {
    href: '/app/financeiro',
    icon: DollarSign,
    title: 'Financeiro'
  },
  {
    href: '/app/cotacoes',
    icon: Grid,
    title: 'Cotações'
  },
  {
    href: '/app/templates',
    icon: Mail,
    title: 'Templates',
    items: [
      {
        href: '/app/newsletter',
        icon: Mail,
        title: 'News Letter'
      }
    ]
  },
  // {
  //   href: '/app/products',
  //   icon: ShoppingBagIcon,
  //   title: 'Products'
  // },
  // {
  //   href: '/app/account',
  //   icon: UserIcon,
  //   title: 'Account'
  // },
  // {
  //   href: '/app/settings',
  //   icon: SettingsIcon,
  //   title: 'Configurações'
  // },
  // {
  //   href: '/login',
  //   icon: LockIcon,
  //   title: 'Login'
  // },
  // {
  //   href: '/register',
  //   icon: UserPlusIcon,
  //   title: 'Register'
  // },
  // {
  //   href: '/404',
  //   icon: AlertCircleIcon,
  //   title: 'Error'
  // }
];

const itemsBottom = [
  {
    href: '/app/account',
    icon: UserIcon,
    title: 'Conta'
  },
  {
    href: '/app/settings',
    icon: SettingsIcon,
    title: 'Configurações'
  },
  {
    href: '/app/logout',
    icon: UserIcon,
    title: 'Sair'
  },
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      {/* <Divider /> */}
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={user.avatar}
          to="/app/account"
        />
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h5"
        >
          {user.name}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {user.jobTitle}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) => (
            <>
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
            {(item.items)&&item.items.map((ite) => {
              return (
              <NavItemSub
                href={ite.href}
                key={ite.title}
                title={ite.title}
                icon={ite.icon}
              />
            );
            })}
            </>
          ))}
        </List>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {itemsBottom.map((item) => (
            <>
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
            {(item.items)&&item.items.map((ite) => {
              return (
              <NavItemSub
                href={ite.href}
                key={ite.title}
                title={ite.title}
                icon={ite.icon}
              />
            );
            })}
            </>
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
