// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const Produtos = {
  "FEIJAO": "FEIJAO",
  "CAFE": "CAFE",
  "SOJA": "SOJA",
  "MILHO": "MILHO",
  "SORGO": "SORGO",
  "ARROZ": "ARROZ",
  "FEIJAOPRETO": "FEIJAOPRETO",
  "FEIJAORAJADO": "FEIJAORAJADO",
  "FEIJAOVERMELHO": "FEIJAOVERMELHO",
  "PULSES": "PULSES",
  "FEIJAOJALO": "FEIJAOJALO"
};

const Veiculos = {
  "TRESQUARTOS": "TRESQUARTOS",
  "BITREM": "BITREM",
  "BITRUCK": "BITRUCK",
  "CAMINHONETE": "CAMINHONETE",
  "CARRETA": "CARRETA",
  "CARRETALS": "CARRETALS",
  "FIORINO": "FIORINO",
  "RODOTREM": "RODOTREM",
  "TOCO": "TOCO",
  "TRUCK": "TRUCK",
  "VAN": "VAN",
  "VANDERLEIA": "VANDERLEIA",
  "VLC": "VLC"
};

const BusinessType = {
  "DEMANDA": "DEMANDA",
  "OFERTA": "OFERTA"
};

const MonetizationType = {
  "FIXO": "FIXO",
  "PERCENTUAL": "PERCENTUAL",
  "COMISSAO": "COMISSAO",
  "CONTRATO": "CONTRATO"
};

const FreteTipo = {
  "FOB": "FOB",
  "CIF": "CIF"
};

const StatusProposta = {
  "ENVIADO": "ENVIADO",
  "ACEITO": "ACEITO",
  "CONTRAPROPOSTA": "CONTRAPROPOSTA",
  "CANCELADO": "CANCELADO",
  "DESISTIDO": "DESISTIDO",
  "RECUPERADO": "RECUPERADO",
  "APAGADO": "APAGADO"
};

const StatusOffer = {
  "INSERIDO": "INSERIDO",
  "ANALISE": "ANALISE",
  "NEGOCIACAO": "NEGOCIACAO",
  "FECHADO": "FECHADO",
  "PAGAMENTO": "PAGAMENTO",
  "CLASSIFICACAO": "CLASSIFICACAO",
  "CARREGAMENTO": "CARREGAMENTO",
  "CONCLUIDO": "CONCLUIDO",
  "CANCELADA": "CANCELADA"
};

const NotificationsType = {
  "OFERTA": "OFERTA",
  "DEMANDA": "DEMANDA",
  "CHAT": "CHAT",
  "NEGOCIO": "NEGOCIO",
  "DEAL": "DEAL",
  "PAGAMENTO": "PAGAMENTO",
  "CONTATO": "CONTATO",
  "VISITA": "VISITA",
  "ADD": "ADD",
  "DELETE": "DELETE",
  "CLASSIFICACAO": "CLASSIFICACAO"
};

const NotificationsSubType = {
  "MENSAGEM": "MENSAGEM",
  "PROPOSTA": "PROPOSTA",
  "FOTO": "FOTO",
  "MATCH": "MATCH",
  "OFERTA": "OFERTA",
  "DEMANDA": "DEMANDA",
  "FECHADO": "FECHADO",
  "CANCELADO": "CANCELADO",
  "CONTRAPROPOSTA": "CONTRAPROPOSTA",
  "AMOSTRA": "AMOSTRA"
};

const FunruralRegime = {
  "RECEITA": "RECEITA",
  "FOLHA": "FOLHA"
};

const ProdutoVariedade = {
  "PEROLA": "PEROLA",
  "ESTILO": "ESTILO",
  "DAMA": "DAMA",
  "AGRONORTE": "AGRONORTE",
  "BRSFC": "BRSFC",
  "ERVILHA": "ERVILHA",
  "GRAODEBICO": "GRAODEBICO",
  "LENTILHA": "LENTILHA",
  "PADRAO": "PADRAO"
};

const LocalArmazenamento = {
  "NONE": "NONE",
  "BIGBAG": "BIGBAG",
  "SILO": "SILO",
  "CAMINHAO": "CAMINHAO",
  "SILOBOLSA": "SILOBOLSA",
  "BARRACAO": "BARRACAO",
  "CAMARAFRIA": "CAMARAFRIA",
  "ARMAZEM": "ARMAZEM",
  "SINDICATO": "SINDICATO"
};

const OptionBoolean = {
  "SIM": "SIM",
  "NAO": "NAO"
};

const Unidade = {
  "SACA": "SACA",
  "TONELADA": "TONELADA",
  "KG": "KG",
  "BAG": "BAG"
};

const { Payment, Documento, Cotacoes, CotacaoRegiao, OrderDocumentos, GeoLocation, Localizacao, UserAssinatura, Order, Frete, FreteCotacao, MessageProposta, Notifications, UserConfig, UserContabil, UserBanco, UserEmpresa, PushDevices, Sample, SampleFotos, Clasification, ClassificationFotos, Classifier, Demand, Chat, Message, MessageAttachment, UserAgrify, Offer, OfferFotos, ModelMessageConnection, EmailTemplates, NewsLetter } = initSchema(schema);

export {
  Payment,
  Documento,
  Cotacoes,
  CotacaoRegiao,
  OrderDocumentos,
  GeoLocation,
  Localizacao,
  UserAssinatura,
  Order,
  Frete,
  FreteCotacao,
  MessageProposta,
  Notifications,
  UserConfig,
  UserContabil,
  UserBanco,
  UserEmpresa,
  PushDevices,
  Sample,
  SampleFotos,
  Clasification,
  ClassificationFotos,
  Classifier,
  Demand,
  Chat,
  Message,
  MessageAttachment,
  UserAgrify,
  Offer,
  OfferFotos,
  ModelMessageConnection,
  EmailTemplates,
  NewsLetter,
  Produtos,
  Veiculos,
  BusinessType,
  MonetizationType,
  FreteTipo,
  StatusProposta,
  StatusOffer,
  NotificationsType,
  NotificationsSubType,
  FunruralRegime,
  ProdutoVariedade,
  LocalArmazenamento,
  OptionBoolean,
  Unidade
};