import * as Utils from "./utils";


export function getFieldValueFromFormData(field, arrayData) {
    let retorno = null;
    const objeto = arrayData.find(data => data.field === field);
    if (objeto) {
        retorno = objeto.value;
    }
    //console.log('Objeto: ' + JSON.stringify(objeto));
    return retorno;
}

export function getPostDataFieldValue(field, arrayData) {
    let retorno = null;
    const objeto = arrayData.find(data => data.field === field);
    if (objeto) {
        retorno = objeto.value;
    }
    //console.log('Objeto: ' + JSON.stringify(objeto));
    return retorno;
}

export function getObjectFromPostData(postData) {
    let retorno = {}

    for (var key in postData) {
        const propriedade = postData[key].field
        const valor = filterValue(postData[key].value)
        if (valor != null) {
            retorno[propriedade] = valor;
        }
    }

    return retorno;
}

export function getObjectFromFormData(formData) {
    let retorno = {}

   for (var objeto in formData) {
        const propriedade = formData[objeto].data.field
        const valor = filterValue(formData[objeto].data.value)

        if (valor != null) {
            retorno[propriedade] = valor;
        }
    }

    /*const postData = getPostData(formData);
    const retorno = getObjectFromPostData(postData);*/

    return retorno;

}

export function getPostDataFromObject(objeto)
{
    let retorno = []

    for (var obj in objeto) {
        const propriedade = obj
        const valor = objeto[obj]
        retorno.push({
            "field": propriedade,
            "value": valor
        });
    }

    /*const postData = getPostData(formData);
    const retorno = getObjectFromPostData(postData);*/

    return retorno;

}

export function getPostData(itens) {
    let retorno = [];

    if (!itens) return retorno;

    itens.map((item, key) => {
        if (item.type !== "resume") {
            retorno.push(item.data);
        }
    });

    return retorno;
}

export function getPostDataObject(obj) {
    let retorno = [];

    for (var key in obj) {
        retorno.push({
            "field": key,
            "value": obj[key]
        });
    }

    return retorno;
}


export function setFormDataFieldValue(field, value, arrayData) {

    for (var objeto in arrayData) {
        if ((arrayData[objeto].data.field).match(field)) {
            arrayData[objeto].data.value = value;
        }
    }

    return arrayData;
}

export function fillFormDataWithPostData(arrayData, postData) {

    for (var objeto in arrayData) {
        arrayData[objeto].data.value = getPostDataFieldValue(arrayData[objeto].data.field, postData)
    }

    return arrayData;
}

export function fillFormDataWithObject(arrayData, object) {

    for (var objeto in arrayData) {
        arrayData[objeto].data.value = object[arrayData[objeto].data.field];
    }

    return arrayData;
}

export function filterValue(value) {
    if (!value) return null;

    if (value === 'true')
        return true
    else if (value === 'false')
        return false
    else return value;
}

export function valueToText(value) {
    //if (!value) return null;
    if ((value == null) && (typeof(value) == undefined)) return null;

    const valorDictionary = dictionary[value];
    if ((valorDictionary != null) && (typeof(valorDictionary) != undefined)) return valorDictionary;

    if (value === true)
        return 'Sim'
    else if (value === false)
        return 'Não'
    else return value;
}

export const dictionary = {
    RECEITA : "Sobre a Receita",
    FOLHA : "Sobre a Folha",

    PEROLA : "Pérola",
    ESTILO : "Estilo",
    DAMA : "Dama",
    AGRONORTE : "Agronorte",
    BRSFC : "BRS FC 402",

    NONE : "Não Possui",
    BIGBAG : "Big Bag",
    SILO : "Silo",
    CAMINHAO : "Caminhão",
    SILOBOLSA : "Silo Bolsa",
    BARRACAO : "Barracão",
    CAMARAFRIA : "Camara Fria",
    ARMAZEM : "Armazém",

    FOB : "FOB",
    CIF : "CIF",

    FEIJAO : "Feijão Carioca",
    CAFE : "Café",
    SOJA : "Soja",
    MILHO : "Milho",
    SORGO : "Sorgo",
    ARROZ : "Arroz",
    FEIJAOPRETO : "Feijão Preto",
    FEIJAORAJADO : "Feijão Rajado",
    FEIJAOVERMELHO : "Feijão Vermelho",
    PULSES : "Pulses",
    ERVILHA : "Ervilha",
    GRAODEBICO: "Grão de Bico",
    LENTILHA: "Lentilha",
    PADRAO: "Variedade Padrão",

    SACA : "Saca",
    TONELADA : "Tonelada",
    KG : "Kg",
    BAG : "Bag",

    INSERIDO : "Inserido no sistema",
    ANALISE : "Em Análise",
    NEGOCIACAO : "Em Negociação",
    FECHADO : "Negócio Fechado",
    PAGAMENTO : "Aguardando Pagamento",
    CLASSIFICACAO : "Em Classificação",
    CARREGAMENTO : "Aguardadno Carregamento",
    CONCLUIDO : "Concluída",
    CANCELADA : "Cancelada",
};

export function numberToMoney(valor) {
    if (valor) {
        return Utils.currencyFormat(valor);
    } else {
        return '';
    }
}

export function getDictionary(valor)
{

}

export function makeId(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';//'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function makeOfertaID()
{
    return makeId(6);
}

export function makeDemandaID()
{
    return makeId(6);
}