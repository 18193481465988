import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import CustomerListView from 'src/views/customer/CustomerListView';
import CustomerListSimple from 'src/views/customer/CustomerListSimple';
import DemandasListView from 'src/views/demandas';
import OrderListView from 'src/views/order';
import OfertasListView from 'src/views/ofertas';
import CotacoesListView from 'src/views/cotacoes';
import NewsLetterListView from 'src/views/newsletter';
import BusinessListView from 'src/views/business/index';
import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProductListView from 'src/views/product/ProductListView';
import RegisterView from 'src/views/auth/RegisterView';
import SettingsView from 'src/views/settings/SettingsView';
import TemplatesListView from 'src/views/templates';
// import * as session from './utils/session';

function Logout(props) {

  useEffect(() => {
    {
      localStorage.setItem('token', null);
      // navigate('/', { replace: true });
    }
    return () => {
    }
  }, [])

  return(<div><Navigate to="/" /></div>)
}

// const token = session.getToken();

// let routes = [
//   {
//     path: 'app',
//     element: <DashboardLayout />,
//     children: [
//       { path: 'account', element: <AccountView /> },
//       { path: 'customers', element: <CustomerListView /> },
//       { path: 'business', element: <BusinessListView /> },
//       { path: 'ofertas', element: <OfertasListView /> },
//       { path: 'demandas', element: <DemandasListView /> },
//       { path: 'dashboard', element: <DashboardView /> },
//       { path: 'products', element: <ProductListView /> },
//       { path: 'settings', element: <SettingsView /> },
//       { path: '*', element: <Navigate to="/404" /> }
//     ]
//   },
//   {
//     path: '/',
//     element: <MainLayout />,
//     children: [
//       { path: 'login', element: <LoginView /> },
//       { path: 'register', element: <RegisterView /> },
//       { path: '404', element: <NotFoundView /> },
//       { path: '/', element: <Navigate to="/login" /> },
//       { path: '*', element: <Navigate to="/app/dashboard" /> }
//     ]
//   }
// ];



const routes = (isLoggedIn) => [
  {
    path: '/app',
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      { path: 'account', element: <AccountView /> },
      { path: 'customers', element: <CustomerListView /> },
      { path: 'customersList', element: <CustomerListSimple /> },
      { path: 'business', element: <BusinessListView /> },
      { path: 'ofertas', element: <OfertasListView /> },
      { path: 'demandas', element: <DemandasListView /> },
      { path: 'pedidos', element: <OrderListView /> },
      { path: 'templates', element: <TemplatesListView /> },
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'products', element: <ProductListView /> },
      { path: 'settings', element: <SettingsView /> },
      { path: 'cotacoes', element: <CotacoesListView /> },
      { path: 'newsletter', element: <NewsLetterListView /> },
      { path: 'logout', element: <Logout /> },
      { path: '*', element: <NotFoundView /> }
    ],
  },
  {
    path: '/',
    element: !isLoggedIn ? <MainLayout /> : <Navigate to="/app/dashboard" />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: 'register', element: <RegisterView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ],
  },
];


export default routes;
