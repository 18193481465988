import React, { memo, useState, useEffect } from "react";
import { DragSource } from "react-dnd";
import moment from 'moment';
import {
  Avatar,
  Box as Boxa,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles
} from "@material-ui/core";
import { DataStore, SortDirection } from "aws-amplify";
import {
  Chat,
  UserAgrify,
  Offer,
  Demand,
  Produtos,
  ProdutoVariedade
} from "../../models";
import getInitials from "src/utils/getInitials";
const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

// const style = {
//     border: '1px dashed gray',
//     backgroundColor: 'white',
//     padding: '0.5rem 1rem',
//     marginRight: '1.5rem',
//     marginBottom: '1.5rem',
//     cursor: 'move',
//     float: 'left',
// };

const style = {
  height: "12rem",
  width: "12rem",
  minWidth: "12rem",
  marginRight: "1.5rem",
  marginBottom: "1.5rem",
  border: "1px solid gray",
  backgroundColor: "white",
  padding: "1rem",
  textAlign: "center",
  fontSize: "1rem",
  lineHeight: "normal",
  float: "left",
  cursor: "move"
};

export const Box = memo(function Box({
  oferta,
  name,
  isDropped,
  isDragging,
  user,
  connectDragSource
}) {

  const classes = useStyles();

  const opacity = isDragging ? 0.4 : 1;


  return connectDragSource(
    // eslint-disable-next-line
    <div role="Box" style={{ ...style, opacity }}>
      {isDropped ? <s>{name}</s> : name}
      <div style={{ textAlign: "left" }}>
        <p>{oferta?.variedade}</p>
        <p>Quantidade: {oferta?.volume}</p>
        <p>Nota: {oferta?.nota}</p>
        <p>
          Periodo:{" "}
          {`${moment(oferta?.dataInicio).format("DD/MM")}`}
        </p>
        <p>Preço: {`R$ ${parseFloat(oferta?.valorDesejado).toFixed(2)}`}</p>
        
        {user&&<div style={{marginTop: 5}}><Boxa
            alignItems="center"
            display="flex"
          >
            {(user?.fotoUrl)&&<Avatar
              className={classes.avatar}
              src={user?.fotoUrl}
            >
              {getInitials(user?.nome)}
            </Avatar>}

            {(!user?.fotoUrl)&&<Avatar
              className={classes.avatar}
            >
              {getInitials(user?.nome)}
            </Avatar>}

            <div style={{fontSize: 13}}>
               {`${user?.nome}\n${user?.cidade} ${user?.estado}`}
            </div>

          </Boxa>
        </div>}
      </div>
    </div>
  );
});

export default DragSource(
  props => props.type,
  {
    beginDrag: props => ({ ...props })
  },
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  })
)(Box);
