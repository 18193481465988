import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import DialogProvider from "react-async-dialog"
import App from './App';
/* eslint-disable @typescript-eslint/no-unused-vars */


ReactDOM.render((
  <BrowserRouter>
    <DialogProvider>
      <App />
    </DialogProvider>
  </BrowserRouter>
), document.getElementById('root'));

serviceWorker.unregister();
