import React, { useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Avatar,
  Box,
  Box as Boxa,
  Button,
  Container,
  CardContent,
  CardHeader,
  Divider,
  Card,
  Checkbox,
  InputAdornment,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  SvgIcon,
  makeStyles
} from "@material-ui/core";
import getInitials from "src/utils/getInitials";
// import FacebookIcon from 'src/icons/Facebook';
// import GoogleIcon from 'src/icons/Google';
import FlatList from "flatlist-react";
import { Search as SearchIcon } from "react-feather";
import { DataStore, SortDirection } from "aws-amplify";
import {
  Chat,
  UserAgrify,
  Offer,
  Demand,
  Produtos,
  ProdutoVariedade
} from "../../models";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

function MatchListOferta(props) {
  const { dados, className, customers, titulo, ...rest } = props;
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [openEdit, setOpenEdit] = useState(false);
  const [userEdit, setUserEdit] = useState();
  const [ofertaUsers, setOfertaUsers] = useState([]);
  const [data, setData] = useState(dados);
  const [search, setSearch] = useState();


  useEffect(() => {
    if ((!search)||(search == '')||(typeof(search)==undefined))
    {
      setData(dados);
    } else {
      const query = dados.filter(el => (((el?.numero?.toLowerCase()).includes(search.toLowerCase())) ||
                                        ((ofertaUsers[el?.id]?.offer?.numero?.toLowerCase()).includes(search.toLowerCase())) ||
                                        ((ofertaUsers[el?.id]?.demand?.numero?.toLowerCase()).includes(search.toLowerCase())) ||
                                        ((ofertaUsers[el?.id]?.seller?.nome?.toLowerCase()).includes(search.toLowerCase())) ||
                                        ((ofertaUsers[el?.id]?.buyer?.nome?.toLowerCase()).includes(search.toLowerCase()))
                                        ));
      setData(query);
    }
    return () => {
    }
  }, [search, dados])

  useEffect(async () => {
    let retorno = {};

    for (var obj in dados) {
      const element = dados[obj];

      const seller = await DataStore.query(UserAgrify, c =>
        c.id("eq", element.sellerID)
      );
      const buyer = await DataStore.query(UserAgrify, c =>
        c.id("eq", element.buyerID)
      );
      const offer = await DataStore.query(Offer, c =>
        c.id("eq", element.offerID)
      );
      const demand = await DataStore.query(Demand, c =>
        c.id("eq", element.demandID)
      );

      const propriedade = element.id;
      const valor = {
        seller: seller[0],
        buyer: buyer[0],
        offer: offer[0],
        demand: demand[0]
      };
      retorno[propriedade] = valor;
    }

    setOfertaUsers(retorno);

    return () => {};
  }, [dados]);

  const handleSelectAll = event => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = data.map(customer => customer.id);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(1)
      );
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = event => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleClose = () => {
    setOpenEdit(false);
  };

  async function ApagarLinha(id) {
    const modelToDelete = await DataStore.query(Chat, id);

    if (
      window.confirm(`Deseja apagar a Match ${modelToDelete.id} selecionada?`)
    ) {
      if (
        window.confirm(
          `Comfirma a exclusão de ${modelToDelete.id}?\nEssa operação não pode ser desfeita.`
        )
      ) {
        const sucesso = DataStore.delete(modelToDelete);
        if (sucesso) {
          alert("Usuário excluído com sucesso");
          setSelectedCustomerIds([]);
        }
      }
    }
  }

  function TableLinha(props) {
    const { customer, index, infos } = props;
    const [seller, setSeller] = useState({ nome: "", fotoUrl: "" });
    const [buyer, setBuyer] = useState({ nome: "", fotoUrl: "" });
    const [offer, setOffer] = useState({ numero: "" });
    const [demand, setDemand] = useState({ numero: "" });

    useEffect(async () => {
      if (infos) {
        setSeller(infos.seller);
        setBuyer(infos.buyer);
        setOffer(infos.offer);
        setDemand(infos.demand);
        //console.log(JSON.stringify(infos));
      }

      return () => {};
    }, []);

    return (
      <TableRow
        hover
        key={customer.id}
        selected={selectedCustomerIds.indexOf(customer.id) !== -1}
      >
        <TableCell padding="checkbox">
          <Checkbox
            checked={selectedCustomerIds.indexOf(customer.id) !== -1}
            onChange={event => handleSelectOne(event, customer.id)}
            value="true"
          />
        </TableCell>

        <TableCell>{customer.numero}</TableCell>

        <TableCell>{moment(customer.matchAt).format("DD/MM/YYYY")}</TableCell>

        <TableCell>
          <Box alignItems="center" display="flex">
            {/* <Avatar className={classes.avatar} src={seller.fotoUrl}>
              {getInitials(seller.nome)}
            </Avatar> */}
            {seller.fotoUrl&&<Avatar
              className={classes.avatar}
              src={seller.fotoUrl}
            >
              {getInitials(seller.nome)}
            </Avatar>}

            {!seller.fotoUrl&&<Avatar
              className={classes.avatar}
            >
              {getInitials(seller.nome)}
            </Avatar>}


            <Typography color="textPrimary" variant="body1">
              {seller.nome}
            </Typography>
          </Box>
        </TableCell>

        <TableCell>
          <Box alignItems="center" display="flex">
            {/* <Avatar className={classes.avatar} src={buyer.fotoUrl}>
              {getInitials(buyer.nome)}
            </Avatar> */}

            {buyer.fotoUrl&&<Avatar
              className={classes.avatar}
              src={buyer.fotoUrl}
            >
              {getInitials(buyer.nome)}
            </Avatar>}

            {!buyer.fotoUrl&&<Avatar
              className={classes.avatar}
            >
              {getInitials(buyer.nome)}
            </Avatar>}

            <Typography color="textPrimary" variant="body1">
              {buyer.nome}
            </Typography>
          </Box>
        </TableCell>

        <TableCell>{`${offer.numero}`}</TableCell>

        <TableCell>{`${demand.numero}`}</TableCell>

        <TableCell align="right">{`${customer.tax || 0}%`}</TableCell>

        <TableCell>{`${customer.status}`}</TableCell>
      </TableRow>
    );
  }

  return (
    <div>
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardHeader
          action={
            <div>
              <TextField
            // maxWidth={500}
            style={{ marginRight: 20 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              )
            }}
            placeholder={`Procurar ${titulo}`}
            value={search}
            onChange={(event) => {
              setSearch(event.target.value);
            }}
            variant="outlined"
          />
              <Button
                color="primary"
                variant="contained"
                style={{ marginRight: 20 }}
                disabled={selectedCustomerIds.length != 1}
                onClick={() => {
                  const found = data.find(
                    element => element.id == selectedCustomerIds[0]
                  );
                  setUserEdit(found);
                  setOpenEdit(true);
                }}
              >
                Editar
              </Button>

              <Button
                color="primary"
                variant="contained"
                style={{ marginRight: 20 }}
                disabled={selectedCustomerIds.length != 1}
                onClick={() => {
                  ApagarLinha(selectedCustomerIds[0]);
                }}
              >
                Apagar
              </Button>

              <Button
                endIcon={<ArrowDropDownIcon />}
                size="small"
                variant="text"
              >
                Ativas
              </Button>
            </div>
          }
          title={titulo}
        />
        <Divider />
        <CardContent>
          <PerfectScrollbar>
            <Box minWidth={1050}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedCustomerIds.length === data.length}
                        color="primary"
                        indeterminate={
                          selectedCustomerIds.length > 0 &&
                          selectedCustomerIds.length < data.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell>Número</TableCell>
                    <TableCell>Match</TableCell>
                    <TableCell>Vendedor</TableCell>
                    <TableCell>Comprador</TableCell>
                    <TableCell>Oferta</TableCell>
                    <TableCell>Demanda</TableCell>
                    <TableCell>Taxa</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {data.slice(0, limit).map((customer, index) => (
                    <TableLinha
                      key={index}
                      customer={customer}
                      infos={ofertaUsers[customer.id]}
                    />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
        </CardContent>
        <TablePagination
          component="div"
          count={data.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>

      <Dialog
        open={openEdit}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <Container maxWidth="sm">
            <Formik
              initialValues={userEdit}
              validationSchema={Yup.object().shape({})}
              onSubmit={async (values, { setSubmitting }) => {
                const resultado = await DataStore.save(
                  Chat.copyOf(userEdit, updated => {
                    updated.tax = values.tax;
                  })
                );

                handleClose();
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box mb={3}>
                    <Typography color="textPrimary" variant="h2">
                      {`Editar Match ${values.numero}`}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    ></Typography>
                  </Box>
                  <TextField
                    error={Boolean(touched.tax && errors.tax)}
                    fullWidth
                    helperText={touched.tax && errors.tax}
                    label="Taxa Agrify"
                    margin="normal"
                    name="tax"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.tax}
                    variant="outlined"
                    type="number"
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                  />
                  <Box my={2}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Salvar
                    </Button>
                  </Box>
                  <Box my={2}>
                    <Button
                      color="primary"
                      fullWidth
                      size="large"
                      variant="contained"
                      onClick={handleClose}
                    >
                      Cancelar
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default MatchListOferta;
